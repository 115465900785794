import * as React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import { apiUrl } from "../../componets/apiConfig";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OtpInput from "react-otp-input";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit">
        Masys
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function validateForm(values) {
  const errors = {};

  if (!values.MobileNumber) {
    errors.MobileNumber = "Enter Mobile Number";
  }
  return errors;
}

const defaultTheme = createTheme();

const CustomerLogin = () => {
  const [isLoading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [customer, setCustomer] = useState({});
  const [values, setValues] = useState({
    MobileNumber: "",
    Password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = `${apiUrl}/`;
  const [errors, setErrors] = useState({
    MobileNumber: "",
    Password: "",
  });
  const [loginError, setLoginError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (Object.keys(customer).length === 0) {
      const formErrors = validateForm(values);
      setErrors(formErrors);
      if (Object.keys(formErrors).length === 0) {
        setLoading(true);
        axios
          .get(`${apiUrl}/getLoanCustomer`, { params: values })
          .then((res) => {
            console.log(res.data);
            if (res.data.length > 0) {
              setCustomer(res.data[0]);
              // navigate("/CutomerAgreement/" + res.data[0].id);
              setLoginError("");
              setLoading(false);
            } else {
              setLoginError("Invalid Mobile Number, Please check your Mobile Number.");
              setLoading(false);
            }
          })
          .catch((err) => {
            console.error(err);
            setLoginError("An error occurred during login.");
            setLoading(false);
          });
      }
    }
    else {
      if (parseInt(otp) === parseInt(customer.OTP)) {
        setLoading(true);
        axios
          .post(`${apiUrl}/CreateCustomercookies`, customer)
          .then((res) => {
            if (res.data.Status === "Success") {
              console.log(res.data)
              navigate("/CustomerAgreement/" + customer.id);
              setLoading(false);
            } else {
              setLoginError("Login Failed, Please check your Credentials.");
              setLoading(false);
            }
          })
          .catch((err) => {
            console.error(err);
            setLoginError("An error occurred during login.");
          });
      }
      else {
        setLoginError("Invalid OTP!!");
      }
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <div className="container-login">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-4 col-md-4 mt-5">
            <div className="card shadow-sm ">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="login-form">
                      <div className="brand-logo" align="center">
                        <img style={{ width: "125px" }} src="/img/loginLogo.png" alt="logo" />
                      </div>
                      <h5 align="center" className="mt-1">
                        {Object.keys(customer).length === 0 ? (
                          <b>Sign In</b>) : (
                          <b style={{ textAlign: "center" }}>Enter OTP</b>)
                        }
                      </h5>
                      {loginError && (
                        <Typography
                          variant="body2"
                          color="error"
                          sx={{ mt: 1 }} align="center"
                        >
                          {loginError}
                        </Typography>
                      )}

                      <form className="pt-3" onSubmit={handleSubmit}>
                        {Object.keys(customer).length === 0 ? (
                          // Show Mobile Number input if customer data is empty
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="MobileNumber"
                            label="Mobile Number"
                            name="MobileNumber"
                            autoComplete="MobileNumber"
                            autoFocus
                            onChange={(e) =>
                              setValues({ ...values, MobileNumber: e.target.value })
                            }
                            error={Boolean(errors.MobileNumber)}
                            helperText={errors.MobileNumber}
                            sx={{ width: "100%" }}
                          />
                        ) : (
                          // Show OTP input if customer data is present                            
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <OtpInput
                              value={otp}
                              onChange={setOtp}
                              numInputs={4}
                              renderSeparator={<span>&nbsp;&nbsp;</span>}
                              renderInput={(props) => (
                                <input
                                  {...props}
                                  className="form-control"
                                  style={{ width: "40px" }}
                                />
                              )}
                            />
                          </div>
                        )}

                        {!isLoading && (
                          <Button
                            className="btn btn-primary"
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, width: "100%" }}
                          >
                            {Object.keys(customer).length === 0 ? "Get OTP" : "Verify OTP"}
                          </Button>
                        )}
                        {isLoading && (
                          <Button
                            className="btn btn-primary"
                            type="submit"
                            fullWidth
                            disabled
                            variant="contained"
                            sx={{ mt: 3, mb: 2, width: "100%" }}
                          >
                            <CircularProgress size={21} color="inherit" />
                            {"  "} {Object.keys(customer).length === 0 ? "Requesting OTP..." : "Verifying OTP..."}
                          </Button>
                        )}

                        <Copyright sx={{ mt: 5 }} />
                      </form>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default CustomerLogin;
