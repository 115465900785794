import React, { useState, useEffect } from "react";
import "./CustomerPreview.css";
import { Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import useFullPageLoader from "../../mui/useFullPageLoader";
import dayjs from "dayjs";
import { apiUrl } from "../../componets/apiConfig";
import { useUser } from "../../componets/UserContext";
import { useNavigate, useParams, Link } from "react-router-dom";
import InterestPayment from "../Reports/InterestPayment";
import ManualPayment from "../Reports/ManualPayment";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";

const FixedInterest = () => {
  const { userData } = useUser();
  const [isModall, setIsModall] = useState(false);
  const [indexValue, setIndex] = useState();
  const [isModal, setIsModal] = useState(false);
  const [isModalpay, setIsModalpay] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const { Sr_No } = useParams();
  const [data, setData] = useState([]);
  const [Paymentdata, setPaymentData] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [Interest, setInterestData] = useState([]);
  const [InterestAmount, setInterestAmount] = useState(0);

  const openPayment = async () => {
    // setIndex(index);
    if (Sr_No) {
      showLoader();
      const res = await axios.get(`${apiUrl}/getpaymenyDetails/${Sr_No}`);
      setPaymentData(res.data);
      hideLoader();
    }
    setIsModalpay(true);
  };

  const onClosedPayment = () => {
    setIsModalpay(false);
    Load();
  };

  const calculateMIP = (
    startDate,
    endDate,
    principalAmount,
    monthlyInterestRate,
    monthDayscount
  ) => {
    const countDays = calculateDaysDifference(startDate, endDate);
    const updatedDays = countDays + 1;
    const InterestAmount = (principalAmount * monthlyInterestRate) / 100;
    const monthlyPayout = (InterestAmount * updatedDays) / monthDayscount;

    return monthlyPayout;
  };

  const calculateRegularMIP = (principalAmount, monthlyInterestRate) => {
    const monthlyPayout = (principalAmount * monthlyInterestRate) / 100;

    return monthlyPayout;
  };

  const calculateCloseMIP = (
    startDate,
    endDate,
    principalAmount,
    monthlyInterestRate,
    monthDayscount,
    LastPayableIntdays,
    Status
  ) => {
    const countDays = calculateDaysDifference(
      new Date(startDate),
      new Date(endDate)
    );
    let updatedDays = 0;
    if (Status !== "Closed") {
      updatedDays = countDays + 1;
    } else {
      updatedDays = countDays + 1 - LastPayableIntdays;
    }
    const InterestAmount = (principalAmount * monthlyInterestRate) / 100;
    const monthlyPayout = (InterestAmount * updatedDays) / monthDayscount;

    return monthlyPayout;
  };

  function areDatesInSameMonthAndYear(date1, date2) {
    return (
      date1.year() === date2.year() && date1.month() + 1 === date2.month() + 1
    );
  }

  const generateDateRangeArray = (data, loadInterest) => {
    console.log(data)
    console.log(loadInterest)

    const dateRangeArray = [];
    let startDate;
    let endDate = null;
    let monthDaysCount = 0;

    for (let i = 0; i < data.length; i++) {      
      debugger;
      const InvestmentStatus = data[i].InvestmentStatus
      startDate = dayjs(data[i].Start_Date);
      endDate = data[i].Request_Date
        ? dayjs(data[i].Request_Date).subtract(
          parseInt(`${data[i].Last_Payable_Int_days}`),
          "day"
        )
        : dayjs().endOf("month");
      const capitalAmount = data[i].Capital_Amount;
      let InvestedAmount = data[i].Investment_Amount;
      const monthlyInterestRate = data[i].Rate_Of_Int;
      const currentDate = dayjs(); // Get today's date

      monthDaysCount = dayjs(data[i].Start_Date).daysInMonth();

      // let startMonth = startDate.month() + 1;
      // let startYear = startDate.year();

      // let endMonth = endDate.month() + 1;
      // let endYear = endDate.year();
      const sameMonthAndYear = areDatesInSameMonthAndYear(startDate, endDate);
      // if (data[i].Request_Date) {
      if (!sameMonthAndYear) {
        
        let NewCapitalAmount = 0;
        if(i>0)
        {
          if(data[i].Type === "Topup")
          {
            //Checking if topup is made at the last day of the month then start date will be 
            let Month = new Date(startDate).getMonth();
            let Year = new Date(startDate).getFullYear();
            let closeDate = dayjs(data[i-1].Request_Date).format("DD/MM/YYYY");
            let LastMonthEndDate = dayjs(new Date(Year, Month, 0)).format("DD/MM/YYYY");
            if(closeDate === LastMonthEndDate)
            {
              NewCapitalAmount = capitalAmount;
            }
            else{
              NewCapitalAmount = InvestedAmount;
            }
          }
        }
        const initialObject = {
          Customer_Id: data[i].Sr_No,
          Enrollment_No: data[i].Enrollment_No,
          Start_Date: data[i].Start_Date,
          startDate: startDate.format(),
          enddate: startDate.endOf("month").format(),
          Month: new Date(startDate).getMonth() + 1,
          Year: new Date(startDate).getFullYear(),
          Capital_Amount: NewCapitalAmount,
          Rate_Of_Int: data[i].Rate_Of_Int,
          InterestAmount: calculateMIP(
            // Change interestamount to InterestAmount
            startDate,
            startDate.endOf("month"),
            NewCapitalAmount,
            monthlyInterestRate,
            monthDaysCount
          ),
          First_Name: data[i].First_Name,
          Middle_Name: data[i].Middle_Name,
          Last_Name: data[i].Last_Name,
          Mobile: data[i].Mobile,
        };

        // Check if the  matches any entry in loadInterest
        const matchingInterest = loadInterest.find(
          (interest) =>
            parseInt(interest.Month) ===
            dayjs(initialObject.startDate).month() + 1 &&
            parseInt(interest.Year) === dayjs(initialObject.startDate).year() &&
            interest.Customer_Id === initialObject.Customer_Id &&
            parseFloat(interest.Amount).toFixed(2) ===
            parseFloat(initialObject.InterestAmount).toFixed(2)
        );

        // Set the status based on matchingInterest
        initialObject.status = matchingInterest ? "Paid" : "Unpaid";
        initialObject.InterestId = matchingInterest
          ? matchingInterest.Sr_No
          : null;

        dateRangeArray.push(initialObject);

        // Create objects for each subsequent month until endDate
        let currentMonth = startDate.add(1, "month");
        while (currentMonth.isBefore(endDate, "day")) {
          const interestObj = {
            Customer_Id: data[i].Sr_No,
            Enrollment_No: data[i].Enrollment_No,
            Start_Date: data[i].Start_Date,
            startDate: currentMonth.startOf("month").format(),
            enddate: currentMonth.endOf("month").format(),
            Month: new Date(startDate).getMonth() + 1,
            Year: new Date(startDate).getFullYear(),
            Capital_Amount: capitalAmount,
            Rate_Of_Int: data[i].Rate_Of_Int,
            InterestAmount: calculateRegularMIP(
              // Change interestamount to InterestAmount
              capitalAmount,
              monthlyInterestRate
            ),
            First_Name: data[i].First_Name,
            Middle_Name: data[i].Middle_Name,
            Last_Name: data[i].Last_Name,
            Mobile: data[i].Mobile,
          };
          let month = dayjs(interestObj.startDate).month() + 1;
          let year = dayjs(interestObj.startDate).year();
          let endMonth = dayjs(endDate).month() + 1;
          let endYear = dayjs(endDate).year();
          if (month === endMonth && year === endYear) {
            currentMonth = currentMonth.add(1, "month");
            continue;
          }
          // console.log(currentMonth.startOf("month").format())
          // Check if the currentMonth matches any entry in loadInterest
          const matchingInterest = loadInterest.find(
            (interest) =>
              parseInt(interest.Month) === month &&
              parseInt(interest.Year) === year &&
              interest.Customer_Id === interestObj.Customer_Id &&
              parseFloat(interest.Amount).toFixed(2) ===
              parseFloat(interestObj.InterestAmount).toFixed(2)
          );

          // Set the status based on matchingInterest
          interestObj.status = matchingInterest ? "Paid" : "Unpaid";
          interestObj.InterestId = matchingInterest
            ? matchingInterest.Sr_No
            : null;

          dateRangeArray.push(interestObj);

          currentMonth = currentMonth.add(1, "month");
        }

        // Check if endDate is after the currentDate
        if (endDate.isAfter(currentDate, 'day')) {
          const currentMonthEndDate = dayjs().endOf('month');
          const currentMonthDaysCount = currentDate.date();

          const currentMonthInterest = calculateMIP(
            currentDate.startOf('month'),
            currentDate,
            capitalAmount,
            monthlyInterestRate,
            dayjs(currentDate.startOf('month')).daysInMonth()
          );

          const currentMonthObj = {
            Customer_Id: data[i].Sr_No,
            Enrollment_No: data[i].Enrollment_No,
            Start_Date: data[i].Start_Date,
            startDate: currentDate.startOf("month").format(),
            enddate: currentDate.format(),
            Month: currentDate.month() + 1,
            Year: currentDate.year(),
            Capital_Amount: capitalAmount,
            Rate_Of_Int: data[i].Rate_Of_Int,
            InterestAmount: currentMonthInterest,
            First_Name: data[i].First_Name,
            Middle_Name: data[i].Middle_Name,
            Last_Name: data[i].Last_Name,
            Mobile: data[i].Mobile,
          };

          // Check if the currentMonthObj matches any entry in loadInterest
          const matchingInterest = loadInterest.find(
            (interest) =>
              parseInt(interest.Month) === currentMonthObj.Month &&
              parseInt(interest.Year) === currentMonthObj.Year &&
              interest.Customer_Id === currentMonthObj.Customer_Id &&
              parseFloat(interest.Amount).toFixed(2) === parseFloat(currentMonthObj.InterestAmount).toFixed(2)
          );

          // Set the status based on matchingInterest
          currentMonthObj.status = matchingInterest ? "Paid" : "Unpaid";
          currentMonthObj.InterestId = matchingInterest ? matchingInterest.Sr_No : null;

          dateRangeArray.push(currentMonthObj);
        }
      }

      let ClosedInCurrentMonth = startDate.year() === currentDate.year() && startDate.month() + 1 === currentDate.month() + 1
        && endDate.year() === currentDate.year() && endDate.month() + 1 === currentDate.month() + 1

      // Add an additional object if Request_Date is not null
      if (data.length > 0 && data[i].Request_Date && !ClosedInCurrentMonth) {
        let j = i;
        if (i < data.length - 1) {
          j = i + 1;
        }
        const lastItem = data[i];
        let CurrentInterest = lastItem.Rate_Of_Int;
        let NextInterest = data[j].Rate_Of_Int;
        let isInterestSame = true;
        if (data[j].Type === "Topup") {
          if (CurrentInterest !== NextInterest) {
            isInterestSame = false;
          }
        }
        if (i > 0) {
          InvestedAmount = data[i].Capital_Amount;
        }
        const capitalAmount = InvestmentStatus !== "Closed" && lastItem.Type !== "Upgrade" ? InvestedAmount : lastItem.Capital_Amount;
        let lastMonthStartDate, lastMonthEndDate;

        if (!sameMonthAndYear) {
          lastMonthStartDate = dayjs(lastItem.Request_Date).startOf("month");
        } else {
          lastMonthStartDate = startDate;
        }
        if (dayjs(lastItem.Request_Date) > currentDate) {
          lastMonthEndDate = currentDate;
        }
        else if (dayjs(lastItem.Request_Date).month() === currentDate.month() && dayjs(lastItem.Request_Date).year() === currentDate.year() && InvestmentStatus === "Closed") {
          lastMonthEndDate = dayjs(lastItem.Request_Date);
        }
        else {
          lastMonthEndDate = InvestmentStatus !== "Closed" && data[j].Type !== "Upgrade" ? dayjs(lastItem.Request_Date).endOf("month") : dayjs(lastItem.Request_Date);
        }
        if (!isInterestSame) {
          lastMonthEndDate = dayjs(lastItem.Request_Date);
        }
        const lastMonthDaysCount = dayjs(lastItem.Request_Date).daysInMonth();

        const lastMonthInterest = calculateCloseMIP(
          lastMonthStartDate,
          lastMonthEndDate,
          //lastItem.Capital_Amount,
          capitalAmount,
          lastItem.Rate_Of_Int,
          lastMonthDaysCount,
          data[i].Last_Payable_Int_days,
          InvestmentStatus
        );

        const lastMonthObj = {
          Customer_Id: lastItem.Sr_No,
          Enrollment_No: lastItem.Enrollment_No,
          Start_Date: data[i].Start_Date,
          startDate: lastMonthStartDate.format(),
          enddate: InvestmentStatus !== "Closed" ? lastMonthEndDate.format() : lastMonthEndDate.subtract(data[i].Last_Payable_Int_days, "days").format(),
          Month: new Date(startDate).getMonth() + 1,
          Year: new Date(startDate).getFullYear(),
          //Capital_Amount: lastItem.Capital_Amount,
          Capital_Amount: capitalAmount,
          Rate_Of_Int: lastItem.Rate_Of_Int,
          InterestAmount: lastMonthInterest, // Change interestamount to InterestAmount
          First_Name: lastItem.First_Name,
          Middle_Name: lastItem.Middle_Name,
          Last_Name: lastItem.Last_Name,
          Mobile: lastItem.Mobile,
        };
        //console.log(lastMonthStartDate)
        // Check if the lastMonthObj matches any entry in loadInterest
        const matchingInterest = loadInterest.find(
          (interest) =>
            parseInt(interest.Month) ===
            dayjs(lastMonthObj.startDate).month() + 1 &&
            parseInt(interest.Year) === dayjs(lastMonthObj.startDate).year() &&
            interest.Customer_Id === lastMonthObj.Customer_Id &&
            parseFloat(interest.Amount).toFixed(2) ===
            parseFloat(lastMonthObj.InterestAmount).toFixed(2)
        );

        // Set the status based on matchingInterest
        lastMonthObj.status = matchingInterest ? "Paid" : "Unpaid";
        lastMonthObj.InterestId = matchingInterest
          ? matchingInterest.Sr_No
          : null;

        dateRangeArray.push(lastMonthObj);
        if (!isInterestSame) {
          if (InvestmentStatus !== "Closed" && currentDate > dayjs(lastItem.Request_Date)) {
            lastMonthStartDate = dayjs(lastItem.Request_Date).add(1, 'day');
            lastMonthEndDate = dayjs(lastItem.Request_Date).endOf("month")
            let isMonthYearCurrentMonth = lastMonthStartDate.year() === currentDate.year() && lastMonthStartDate.month() + 1 === currentDate.month() + 1
              && lastMonthEndDate.year() === currentDate.year() && lastMonthEndDate.month() + 1 === currentDate.month() + 1;
            if (isMonthYearCurrentMonth) {
              lastMonthEndDate = currentDate;
            }
            const lastMonthDaysCount = dayjs(lastItem.Request_Date).daysInMonth();

            const lastMonthInterest = calculateCloseMIP(
              lastMonthStartDate,
              lastMonthEndDate,
              //lastItem.Capital_Amount,
              capitalAmount,
              NextInterest,
              lastMonthDaysCount,
              data[i].Last_Payable_Int_days,
              InvestmentStatus
            );

            const lastMonthObj = {
              Customer_Id: lastItem.Sr_No,
              Enrollment_No: lastItem.Enrollment_No,
              Start_Date: data[i].Start_Date,
              startDate: lastMonthStartDate.format(),
              enddate: lastMonthEndDate.format(),
              Month: new Date(startDate).getMonth() + 1,
              Year: new Date(startDate).getFullYear(),
              //Capital_Amount: lastItem.Capital_Amount,
              Capital_Amount: capitalAmount,
              Rate_Of_Int: NextInterest,
              InterestAmount: lastMonthInterest, // Change interestamount to InterestAmount
              First_Name: lastItem.First_Name,
              Middle_Name: lastItem.Middle_Name,
              Last_Name: lastItem.Last_Name,
              Mobile: lastItem.Mobile,
            };
            //console.log(lastMonthStartDate)
            // Check if the lastMonthObj matches any entry in loadInterest
            const matchingInterest = loadInterest.find(
              (interest) =>
                parseInt(interest.Month) ===
                dayjs(lastMonthObj.startDate).month() + 1 &&
                parseInt(interest.Year) === dayjs(lastMonthObj.startDate).year() &&
                interest.Customer_Id === lastMonthObj.Customer_Id &&
                parseFloat(interest.Amount).toFixed(2) ===
                parseFloat(lastMonthObj.InterestAmount).toFixed(2)
            );

            // Set the status based on matchingInterest
            lastMonthObj.status = matchingInterest ? "Paid" : "Unpaid";
            lastMonthObj.InterestId = matchingInterest
              ? matchingInterest.Sr_No
              : null;

            dateRangeArray.push(lastMonthObj);
          }


        }
        // console.log("DateRangeArray:", dateRangeArray);
      }
      if (ClosedInCurrentMonth) {
        const lastItem = data[i];
        // if (i > 0) {
        //   InvestedAmount = data[i - 1].Capital_Amount;
        // }
        const capitalAmount = InvestmentStatus !== "Closed" && lastItem.Type !== "Upgrade" ? InvestedAmount : lastItem.Capital_Amount;
        let lastMonthStartDate, lastMonthEndDate;
        if (!sameMonthAndYear) {
          lastMonthStartDate = dayjs(endDate).startOf("month");
        } else {
          lastMonthStartDate = startDate;
        }
        if (dayjs(endDate) > currentDate) {
          lastMonthEndDate = currentDate;
        }
        else if (dayjs(endDate).month() === currentDate.month() && dayjs(endDate).year() === currentDate.year()) {
          lastMonthEndDate = dayjs(endDate);
        }
        else {
          lastMonthEndDate = InvestmentStatus !== "Closed" && lastItem.Type !== "Upgrade" ? dayjs(endDate).endOf("month") : dayjs(endDate);
        }

        const lastMonthDaysCount = dayjs(endDate).daysInMonth();

        const lastMonthInterest = calculateCloseMIP(
          lastMonthStartDate,
          lastMonthEndDate,
          //lastItem.Capital_Amount,
          capitalAmount,
          lastItem.Rate_Of_Int,
          lastMonthDaysCount,
          data[i].Last_Payable_Int_days,
          InvestmentStatus
        );

        const lastMonthObj = {
          Customer_Id: lastItem.Sr_No,
          Enrollment_No: lastItem.Enrollment_No,
          Start_Date: data[i].Start_Date,
          startDate: lastMonthStartDate.format(),
          enddate: lastMonthEndDate.format(),
          Month: new Date(startDate).getMonth() + 1,
          Year: new Date(startDate).getFullYear(),
          //Capital_Amount: lastItem.Capital_Amount,
          Capital_Amount: capitalAmount,
          Rate_Of_Int: lastItem.Rate_Of_Int,
          InterestAmount: lastMonthInterest, // Change interestamount to InterestAmount
          First_Name: lastItem.First_Name,
          Middle_Name: lastItem.Middle_Name,
          Last_Name: lastItem.Last_Name,
          Mobile: lastItem.Mobile,
        };
        //console.log(lastMonthStartDate)
        // Check if the lastMonthObj matches any entry in loadInterest
        const matchingInterest = loadInterest.find(
          (interest) =>
            parseInt(interest.Month) ===
            dayjs(lastMonthObj.startDate).month() + 1 &&
            parseInt(interest.Year) === dayjs(lastMonthObj.startDate).year() &&
            interest.Customer_Id === lastMonthObj.Customer_Id &&
            parseFloat(interest.Amount).toFixed(2) ===
            parseFloat(lastMonthObj.InterestAmount).toFixed(2)
        );

        // Set the status based on matchingInterest
        lastMonthObj.status = matchingInterest ? "Paid" : "Unpaid";
        lastMonthObj.InterestId = matchingInterest
          ? matchingInterest.Sr_No
          : null;

        dateRangeArray.push(lastMonthObj);
      }
      // }
      // else {
      //   const initialObject = {
      //     Customer_Id: data[i].Sr_No,
      //     Enrollment_No: data[i].Enrollment_No,
      //     Start_Date: data[i].Start_Date,
      //     startDate: startDate.format(),
      //     enddate: dayjs().format(),
      //     Month: new Date(startDate).getMonth() + 1,
      //     Year: new Date(startDate).getFullYear(),
      //     Capital_Amount: data[i].Type === "Topup" ? InvestedAmount : capitalAmount,
      //     Rate_Of_Int: data[i].Rate_Of_Int,
      //     InterestAmount: calculateMIP(
      //       // Change interestamount to InterestAmount
      //       startDate,
      //       dayjs().format(),
      //       data[i].Type === "Topup" ? InvestedAmount : capitalAmount,
      //       monthlyInterestRate,
      //       monthDaysCount
      //     ),
      //     First_Name: data[i].First_Name,
      //     Middle_Name: data[i].Middle_Name,
      //     Last_Name: data[i].Last_Name,
      //     Mobile: data[i].Mobile,
      //   };

      //   // Check if the  matches any entry in loadInterest
      //   const matchingInterest = loadInterest.find(
      //     (interest) =>
      //       parseInt(interest.Month) ===
      //       dayjs(initialObject.startDate).month() + 1 &&
      //       parseInt(interest.Year) === dayjs(initialObject.startDate).year() &&
      //       interest.Customer_Id === initialObject.Customer_Id &&
      //       interest.Amount ===
      //       parseFloat(initialObject.InterestAmount.toFixed(2))
      //   );

      //   // Set the status based on matchingInterest
      //   initialObject.status = matchingInterest ? "Paid" : "Unpaid";
      //   initialObject.InterestId = matchingInterest
      //     ? matchingInterest.Sr_No
      //     : null;

      //   dateRangeArray.push(initialObject);
      // }
    }
    return dateRangeArray;
  };


  useEffect(() => {
    if (Sr_No && userData && userData.Org_Id) {
      Load();
    }
  }, [Sr_No, userData]);

  const Load = async () => {
    showLoader();
    try {
      const result = await axios.get(
        `${apiUrl}/getInterestLog?OrgId=${userData.Org_Id}&CustomerId=${Sr_No}`
      );
      const res = await axios.get(
        `${apiUrl}/getInterestPaymentLog?OrgId=${userData.Org_Id}&CustomerId=${Sr_No}`
      );

      console.log(result.data);
      console.log(res.data);

      let interestArray = generateDateRangeArray(result.data, res.data);
      interestArray = interestArray.sort((a, b) => {
        const dateComparison = dayjs(b.startDate).diff(dayjs(a.startDate))
        if (dateComparison === 0) {
          return parseFloat(a.Capital_Amount) - parseFloat(b.Capital_Amount);
        } else {
          return dateComparison;
        }
      });
      interestArray = interestArray.filter(item => {
        return dayjs(item.startDate).isAfter('2023-12-31', 'day');
      })
      // console.log(interestArray);

      let InterestPaymentLog = [];
      InterestPaymentLog = res.data;

      InterestPaymentLog = InterestPaymentLog.filter((item) => {
        return dayjs(item.From_Date).isBefore("2024-01-01", "day");
      });

      InterestPaymentLog = InterestPaymentLog.map((item) => ({
        Customer_Id: item.Customer_Id,
        Enrollment_No: item.Enrollment_No,
        Start_Date: item.Start_Date,
        startDate: item.From_Date,
        enddate: item.To_Date,
        Capital_Amount: item.Capital_Amount,
        Rate_Of_Int: item.Rate_Of_Int,
        InterestAmount: item.Amount,
        First_Name: item.First_Name,
        Middle_Name: item.Middle_Name,
        Last_Name: item.Last_Name,
        Mobile: item.Mobile,
        status: "Paid",
        InterestId: item.Sr_No,
      }));

      console.log(InterestPaymentLog);

      interestArray = interestArray.concat(InterestPaymentLog);
      console.log(interestArray);

      console.log("interestArray length:", interestArray.length);

      const totalInterest = interestArray.reduce(
        (sum, item) => sum + item.InterestAmount,
        0
      );

      setInterestAmount(totalInterest);
      setInterestData(interestArray);
      console.log(interestArray);
      setFullData(result.data);
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Warning!",
        text: "Make sure your investment is verified and active.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      hideLoader();
    }
  };

  const calculateDaysDifference = (startDate, endDate) => {
    const start = dayjs(startDate).startOf("day");
    const end = dayjs(endDate).startOf("day");
    const daysDifference = end.diff(start, "day");

    return daysDifference;
  };

  const showSummarry = (index) => {
    setIndex(index);
    setIsModal(true);
  };
  const closed = () => {
    setIsModal(false);
    Load();
  };

  const updateData = async (id) => {
    if (id) {
      showLoader();
      const res = await axios.get(`${apiUrl}/getInterestPaymentLogById/${id}`);
      setData(res.data);
      hideLoader();
    }

    setIsModall(true);
  };

  const onClosed = () => {
    setIsModall(false);
    Load();
  };

  return (
    <div className="card">
      <div className="card-body bio-graph-info">
        <div className="row justify-content-end mr-2">
          <button
            type="button"
            disabled={fullData.length > 0 && fullData[0].IsActive === "Pending"}
            style={{
              cursor: fullData.length > 0 && fullData[0].IsActive === "Pending" ? "not-allowed" : "pointer"
            }}

            className="btn btn-sm btn-success"
            onClick={openPayment}
          >
            Back Dated Record
          </button>
        </div>
        <div className="col-12 mt-2">
          <div className=" table-responsive">
            <table
              id="dataTableHover"
              className="table align-items-center table-flush table-hover"
            >
              <thead>
                <tr>
                  <th className="text-left" style={{ padding: "10px" }}>
                    Sr. No.
                  </th>
                  <th className="text-left" style={{ padding: "10px" }}>
                    Invested Amt.
                  </th>
                  <th className="text-left" style={{ padding: "10px" }}>
                    ROI
                  </th>
                  <th className="text-left" style={{ padding: "10px" }}>
                    From Date
                  </th>
                  <th className="text-left" style={{ padding: "10px" }}>
                    To Date
                  </th>
                  <th className="text-left" style={{ padding: "10px" }}>
                    Days
                  </th>
                  <th className="text-left" style={{ padding: "10px" }}>
                    Interest Amt.
                  </th>
                  <th className="text-center" style={{ padding: "10px" }}></th>
                </tr>
              </thead>
              <tbody>
                {Interest.length > 0 ? (
                  Interest.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        style={
                          item.status === "Paid"
                            ? {
                              backgroundColor: "#66bb6a",
                              color: "white",
                            }
                            : {}
                        }
                      >
                        <td className="text-left" style={{ padding: "10px" }}>
                          {index + 1}{" "}
                        </td>
                        <td className="text-left" style={{ padding: "10px" }}>
                          {item.Capital_Amount}{" "}
                        </td>
                        <td className="text-left" style={{ padding: "10px" }}>
                          {item.Rate_Of_Int}%
                        </td>
                        <td className="text-left" style={{ padding: "10px" }}>
                          {dayjs(item.startDate).format("DD/MM/YYYY")}
                        </td>
                        <td className="text-left" style={{ padding: "10px" }}>
                          {dayjs(item.enddate).format("DD/MM/YYYY")}
                        </td>
                        <td className="text-left" style={{ padding: "10px" }}>
                          {calculateDaysDifference(item.startDate, item.enddate) + 1 || "0"}
                        </td>
                        <td className="text-left" style={{ padding: "10px" }}>
                          {parseFloat(item.InterestAmount).toFixed(2)}
                        </td>
                        <td className="text-center" style={{ padding: "10px" }}>
                          {item.status === "Unpaid" ? (
                            <button
                              disabled={fullData[0].IsActive === "Pending"}
                              style={{
                                cursor: fullData[0].IsActive === "Pending" ? "not-allowed" : "pointer"
                              }}

                              className={item.startDate && dayjs(item.startDate).isSame(dayjs(), 'month') && dayjs(item.startDate).isSame(dayjs(), 'year') ? 'd-none' : 'btn btn-sm btn-success'}
                              onClick={(e) => {
                                e.preventDefault();
                                showSummarry(index);
                              }}
                            >
                              Pay
                            </button>
                          ) : (
                            <Link
                              className="btn btn-sm"
                              onClick={() => {
                                updateData(item.InterestId);
                              }}
                            >
                              <EditIcon
                                className="text-white"
                                style={{ fontSize: "18px" }}
                              />
                            </Link>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="text-center text-danger" colspan={8}>
                      Data Not Found!!
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td
                    colSpan={6}
                    className="text-right"
                    style={{ padding: "10px" }}
                  >
                    <strong aligne="right">Total Interest Amount:</strong>
                  </td>
                  <td
                    colSpan={2}
                    className="text-left"
                    style={{ padding: "10px" }}
                  >
                    <strong>{parseFloat(InterestAmount).toFixed(2)}</strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      {isModal && (
        <InterestPayment
          open={isModal}
          onClose={closed}
          detail={Interest[indexValue]}
          month={dayjs(Interest[indexValue].startDate).month() + 1}
          year={dayjs(Interest[indexValue].startDate).year()}
          type="Submit"
        />
      )}

      {isModall && (
        <>
          {dayjs(data[0]?.From_Date).isBefore("2024-01-01", "day") ?
            <ManualPayment
              open={isModall}
              onClose={onClosed}
              detail={data[0]}
              month={0}
              year={0}
              type="Update"
            /> : <InterestPayment
              open={isModall}
              onClose={onClosed}
              detail={data[0]}
              month={0}
              year={0}
              type="Update"
            />}
        </>

      )}
      {isModalpay && (
        <ManualPayment
          open={isModalpay}
          onClose={onClosedPayment}
          detail={Paymentdata}
          type="Submit"
          month=""
          year=""
        />
      )}
      {loader}
    </div>
  );
};

export default FixedInterest;
