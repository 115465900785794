import React from 'react';

const JointAgreement = () => {
    return (
        <div style={{ textAlign: 'center', padding: '20px', fontSize: '18px', fontWeight: 'bold', color: '#333' }}>
            Joint Agreement Coming Soon
        </div>
    );
};

export default JointAgreement;
