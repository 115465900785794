import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import useFullPageLoader from "../../mui/useFullPageLoader";
import FileDownloadOffIcon from "@mui/icons-material/LinkOff";
import DownloadIcon from "@mui/icons-material/Visibility";
import dayjs from 'dayjs';
import { IconButton } from '@mui/material';
import { apiUrl } from "../../componets/apiConfig";
import { formatDate, formatInputDate } from "../../mui/dateFormate";
import html2pdf from "html2pdf.js";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useUser } from "../../componets/UserContext";
import Swal from 'sweetalert2';
import UpdateLoanAgreement from "./UpdateLoanAgreement"
import AgreementContent from './AgreementContent';

function formatTime(timeString) {
    try {
        if (!timeString || timeString === 'null') return '';
        const [hours, minutes, seconds = '00'] = timeString.split(":").map(num => num.padStart(2, '0'));
        const date = new Date();
        date.setHours(parseInt(hours, 10));
        date.setMinutes(parseInt(minutes, 10));
        date.setSeconds(parseInt(seconds, 10));
        const formattedTime = date.toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }).toUpperCase();
        return formattedTime;
    } catch (error) {
        console.error("Error formatting time:", error);
        return "Invalid Time";
    }
}

const Verification = () => {
    const { userData } = useUser();
    const { id } = useParams();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [customer, setCustomer] = useState({});
    const [errors, setError] = useState({});
    const [images, setImage] = useState([]);
    const [IsPreview, setIsPreview] = useState(false);
    const [transactionDetails, setTransactionDetails] = useState([]);
    const [PayInBank, setPayInBank] = useState([{
        PayIn_Bank_Account_Holder_Name: '',
        PayIn_Bank_Name: '',
        PayIn_Account_Number: '',
        PayIn_IFSC_Code: '',
        PayIn_Branch: '',
        PayIn_Account_Holder_Type: '',
        PayIn_Account_Type: ''
    }]);
    const [PayOutBank, setPayOutBank] = useState([{
        PayOut_Bank_Account_Holder_Name: '',
        PayOut_Bank_Name: '',
        PayOut_Account_Number: '',
        PayOut_IFSC_Code: '',
        PayOut_Branch: '',
        PayOut_Account_Holder_Type: '',
        PayOut_Account_Type: ''
    }]);
    const showSummarry = async () => {
        setIsPreview(true);
    };

    const closed = () => {
        setIsPreview(false);
    };

    const [hr, setHr] = useState(true);

    const [selectedImage, setSelectedImage] = useState(null);



    const handleImageClick = (image) => {
        setSelectedImage(image);
    };



    useEffect(() => {
        loadCustomerData();
    }, []);
    const loadCustomerData = async () => {
        showLoader();
        try {
            const result = await axios.get(
                `${apiUrl}/getLoanAgreementByIdNew/${id}`
            );
            setCustomer(result.data);
            console.log(result?.data);
            setCustomer((e) => ({
                ...e,
                AgDate: result.data.Date,
                Loan_Start_Date: formatInputDate(result.data.Loan_Start_Date),
                Loan_End_Date: formatInputDate(result.data.Loan_End_Date),
                Loan_Disbursal_Date: formatInputDate(result.data.Loan_Disbursal_Date),
                Received_Date: formatInputDate(result.data.Received_Date),
                KYC_Date: formatInputDate(result.data.KYC_Date),
                LockIn_Period_From_Date: formatInputDate(result.data.LockIn_Period_From_Date),
                LockIn_Period_To_Date: formatInputDate(result.data.LockIn_Period_To_Date),
                Nomination_Date: formatInputDate(result.data.Nomination_Date),
                Nominee_DOB: formatInputDate(result.data.Nominee_DOB),
            }));
            const formattedDetails = result.data.transactionDetails.map(detail => ({
                ...detail,
                Received_Date: formatInputDate(detail.Received_Date),
                Start_Date: formatInputDate(detail.Start_Date),
                // Apply formatInputDate to other date fields as needed
            }));
            setTransactionDetails(formattedDetails);
            setPayInBank(result.data.PayInBank);
            setPayOutBank(result.data.PayOutBank);
            setImage([
                `${apiUrl}/FreshAgreement/BankDetail/${result?.data?.Bank_Details}`,
                `${apiUrl}/FreshAgreement/PaymentProof/${result?.data?.Payment_Proof}`,
                `${apiUrl}/FreshAgreement/AadharCard/${result?.data?.Aadhar_Card}`,
                `${apiUrl}/FreshAgreement/PanCard/${result?.data?.Pan_Card}`,
                `${apiUrl}/FreshAgreement/Attachment1/${result?.data?.Attachment1}`,
                `${apiUrl}/FreshAgreement/Attachment2/${result?.data?.Attachment2}`
            ])

            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data: ", error);
        }
    };

    const handleGeneratePDF = () => {
        const element = document.getElementById("printablediv");
        const options = {
            margin: [10, 15, 10, 15],
            filename: 'Fresh_Agreement.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            pagebreak: { mode: ['css', 'legacy'] }
        };

        html2pdf().from(element).set(options).toPdf().get('pdf').then((pdf) => {
            const totalPages = 7;
            while (pdf.internal.pages.length < totalPages) {
                pdf.addPage();
            }
        }).save();
    };

    const generateSavePDF = async () => {
        const element = document.getElementById("printablediv");
        const opt = {
            margin: [10, 15, 10, 15],
            filename: 'Fresh_Agreement.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            pagebreak: { mode: ['css', 'legacy'] }
        };

        try {
            const pdfBlob = await html2pdf().from(element).set(opt).outputPdf("blob");


            //console.log(whatsapp);
            showLoader();

            const formData = new FormData();
            formData.append("Agreement_Doc", pdfBlob, "Fresh_Agreement.pdf");

            await axios.put(`/saveAgreementDoc`, formData);
            hideLoader();


        } catch (error) {
            // Handle any errors
            console.error("Error saving PDF:", error);
            hideLoader();
        }
    };

    async function save(event) {
        event.preventDefault();
        const error = {};
        const updatedCustomer = {
            User_Role: userData?.role,
            Added_By_Employee_Id: userData?.Employee_Id ? userData?.Employee_Id : "",
            Added_By_Id: userData.userId,
            Added_By: `${userData?.firstName} ${userData?.lastName}`,
            Org_Id: userData?.Org_Id,
            UserName: userData?.UserName,
        };

        if (Object.keys(error).length === 0) {
            try {
                showLoader();
                await axios.post(`${apiUrl}/verifyLoanAgreement/${id}`, updatedCustomer);
                Swal.fire("Success!", "Verifid Loan Agreement successfully", "success");
                loadCustomerData();
                hideLoader();
            } catch (err) {
                Swal.fire({
                    title: "Error",
                    text: "Failed to Verify Agreement. Please try again later.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                hideLoader();
            }
        } else {
            setError(error);
        }
    }


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column mb-2">
                        <h5 className="page-title">Agreement Verification</h5>
                    </div>
                    <div className="card mb-2">
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-12 col-lg-12'>
                                            <div className="row">
                                                {images.map((image, index) => {
                                                    // Assuming you have a way to get the document name (e.g., from the image URL)
                                                    const docName = image.split('/').pop(); // Extract the document name from the URL
                                                    const attachmentName = docName.split('_')[0];
                                                    return (
                                                        <div key={index} className="col-2">
                                                            <button
                                                                className="btn btn-sm"
                                                                style={{ backgroundColor: 'rgb(55, 84, 108)', color: 'white' }}
                                                                onClick={() => handleImageClick(image)}
                                                            >
                                                                {attachmentName || 'No Doc'}
                                                            </button>
                                                        </div>

                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-12 col-lg-12'>
                                            {selectedImage && (
                                                <div className="preview-panel">
                                                    <h6>Selected Document:</h6>
                                                    <div className="image-frame">
                                                        {selectedImage.endsWith('.pdf') ? (
                                                            <embed
                                                                src={selectedImage}
                                                                type="application/pdf"
                                                                width="100%"
                                                                height="600px"
                                                                onError={(e) => {
                                                                    e.target.src = ""; // Clear the src
                                                                    e.target.alt = "PDF not found"; // Set the alt text
                                                                }}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={selectedImage}
                                                                alt="Selected"
                                                                className="img-fluid"
                                                                onError={(e) => {
                                                                    e.target.onerror = null; // Prevents infinite loop
                                                                    e.target.src = ""; // Clear the src
                                                                    e.target.alt = "Doc not found"; // Set the alt text
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                    {customer ? (

                                        <div className="div" >
                                            {/* Content divided across pages */}
                                            <div className="row justify-content-center" id="printablediv"
                                                style={{
                                                    padding: "10px",
                                                    color: "#000",
                                                    maxHeight: "1250px",  // Set a max height
                                                    overflowY: "auto",   // Enable vertical scrolling
                                                    overflowX: "hidden"  // Disable horizontal scrolling if needed
                                                }}
                                            >
                                                <AgreementContent customer={customer} formatDate={formatDate} formatTime={formatTime}
                                                    transactionDetails={transactionDetails} PayInBank={PayInBank} PayOutBank={PayOutBank} />
                                            </div>
                                        </div>

                                    ) : (<p>Loadinggg...</p>)}
                                </div>
                            </div>
                            <div className="row justify-content-center mt-3">
                                {/* <div className="row justify-content-center mt-3"> */}
                                <div className="col-md-2">
                                    <button type="submit" className="btn btn-primary mb-1"
                                        onClick={showSummarry}
                                    >
                                        Edit
                                    </button>&nbsp;&nbsp;
                                    {
                                        !(userData?.role === "Accountant" && customer.Verified_DOP) &&
                                        !(userData?.role === "Supervisor" && customer.Verified_Checker) &&
                                        !(userData?.role === "Admin" && customer.Verified_Issuer) && (
                                            <button type="submit" className="btn btn-success mb-1" onClick={save}>
                                                Verify
                                            </button>
                                        )
                                    }
                                </div>

                            </div>
                            {loader}

                        </div>
                    </div>

                </div>
                {IsPreview && (
                    <UpdateLoanAgreement
                        open={IsPreview}
                        close={closed}
                        Id={id}
                    />
                )}
                {loader}
            </div>
        </div>
    );
};
export default Verification;
