import React, { useState, useEffect } from "react";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { useUser } from "../../componets/UserContext";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { apiUrl } from "../../componets/apiConfig";
import { error } from "../Alerts/alert";
import OTP from "./UserOTP";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DownloadIcon from "@mui/icons-material/Download";
import Visibility from "@mui/icons-material/Visibility";

const UpdateUser = () => {
  const { userData } = useUser();
  const [isModal, setIsModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const [PhotoFileName, setPhotoFileName] = useState("Choose file");
  const [EmpDocument1FileName, setEmpDocument1FileName] =
    useState("Choose file");
  const [EmpDocument2FileName, setEmpDocument2FileName] =
    useState("Choose file");
  const { type, EmpId } = useParams();

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [errors, setErrors] = useState({});
  //console.log(isModal);

  const showSummarry = () => {
    setIsModal(true);
  };

  const closed = (e) => {
    setIsModal(false);
    // console.log(e);
    if (e === 'verified') {
      setIsEditing(true);
    }
  };


  const handleEditClick = (e) => {
    if (userData.role !== "Admin" && userDetail.IsActive === 'Active' && type === 'edit') {
      insertOTP(e);
    } else {
      setIsEditing(true);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  function generateOTP() {
    const otp = Math.floor(1000 + Math.random() * 9000);
    return otp.toString();
  }

  useEffect(() => {
    if (EmpId) {
      loade();
    }
  }, [EmpId]);

  const loade = async () => {
    showLoader();
    const result = await axios.get(`${apiUrl}/getUsers/${EmpId}`);
    setuUerDetail(result.data);
    console.log(result.data);

    hideLoader();
  };

  const [adminData, setAdminData] = useState({});

  useEffect(() => {
    axios
      .get(`${apiUrl}/getAdminMobile`)
      .then((res) => {
        const data = res.data;
        // console.log(data);
        setAdminData(data);
      });

  }, []);

  const insertOTP = async (event) => {
    event.preventDefault();

    const newOTP = generateOTP();

    const otp = {
      Employee: `${userData.firstName} ${userData.lastName}`,
      OTP: newOTP,
      Name: `${userDetail.First_Name} ${userDetail.Last_Name}`,
      OrgId: userData.Org_Id,
      Email: userData.email || "",
      number: type === 'edit' ? adminData.Mobile : userData.Mobile,
      messageTitle:
        type === "edit"
          ? "Emplyee Update OTP"
          : "User Verification",
    };

    showLoader();

    axios
      .put(`${apiUrl}/insertUserOpt/${EmpId}`, otp)
      .then((res) => {
        //navigate("/viewCustomer");
        //LoadCategory();
        hideLoader();
        showSummarry();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Error",
          text: "Failed to Generate OTP. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
        hideLoader();
        handleCancelEdit();
      });
  };

  const [userDetail, setuUerDetail] = useState({
    Employee_Id: "",
    Joining_Date: "",
    First_Name: "",
    Middle_Name: "",
    Last_Name: "",
    Gender: "Male",
    DOB: "",
    Email: "",
    Telephone: "",
    Mobile: "",
    Address: "",
    Status: "",
    Role: "Supervisor",
    Username: "",
    Password: "supervisor@123",
    Photo: "",
    EmpDocument1: "",
    EmpDocument2: "",
    OTP: "",
    GCM: "",
    App_Status: "",
    Date_Added: "",
    Added_By_Id: "",
    Added_By: "",
    Org_Id: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    let userPassword = "";
    if (userDetail.Role === "Supervisor") {
      userPassword = "supervisor@123";
    } else if (userDetail.Role === "Accountant") {
      userPassword = "accountant@123";
    } else if (userDetail.Role === "Admin") {
      userPassword = "admin@123";
    } else if (userDetail.Role === "Director") {
      userPassword = "director@123";
    } else if (userDetail.Role === "Manager") {
      userPassword = "manager@123";
    } else if (userDetail.Role === "Executive") {
      userPassword = "executive@123";
    }
    const UpdatedUserDetail = {
      ...userDetail,

      Username: userDetail.Mobile,
      Password: userPassword,
      User_Role: userData.role,
      Emp_Id: userData.Employee_Id,
      Added_By_Id: userData.userId,
      Added_By: `${userData.firstName} ${userData.lastName}`,
      Org_Id: userData.Org_Id,
      UserName: userData.UserName,
    };
    console.log(userDetail);

    const formdata = new FormData();
    formdata.append("Employee_Id", userDetail.Employee_Id);
    formdata.append("Joining_Date", userDetail.Joining_Date);
    formdata.append("First_Name", userDetail.First_Name);
    formdata.append("Last_Name", userDetail.Last_Name);
    formdata.append("DOB", userDetail.DOB);
    formdata.append("Mobile", userDetail.Mobile);
    formdata.append("Email", userDetail.Email);
    formdata.append("Gender", userDetail.Gender);
    formdata.append("Role", userDetail.Role);
    formdata.append("Address", userDetail.Address);
    formdata.append("Password", UpdatedUserDetail.Password);
    formdata.append("Photo", userDetail.Photo);
    formdata.append("EmpDocument1", userDetail.EmpDocument1);
    formdata.append("EmpDocument2", userDetail.EmpDocument2);
    formdata.append("Username", UpdatedUserDetail.Username);
    formdata.append("User_Role", UpdatedUserDetail.User_Role);
    formdata.append("Emp_Id", UpdatedUserDetail.Emp_Id);
    formdata.append("Added_By_Id", UpdatedUserDetail.Added_By_Id);
    formdata.append("Added_By", UpdatedUserDetail.Added_By);
    formdata.append("Org_Id", UpdatedUserDetail.Org_Id);
    formdata.append("UserName", UpdatedUserDetail.UserName);



    const validationErrors = {};
    if (!/^[a-zA-Z\s]+$/.test(userDetail.First_Name)) {
      validationErrors.First_Name = "Invalid Input";
    }
    if (!/^[a-zA-Z\s]+$/.test(userDetail.Last_Name)) {
      validationErrors.Last_Name = "Invalid Input";
    }

    if (!/^([6-9][0-9]{9,11})$/.test(userDetail.Mobile)) {
      validationErrors.Mobile = "Invalid Mobile Number";
    }
    if (
      !/^[\w.-]+@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)+$/.test(userDetail.Email) &&
      userDetail?.Email !== ""
    ) {
      validationErrors.Email = "Invalid Email Address";
    }
    if (Object.keys(validationErrors).length === 0) {
      showLoader();

      axios
        .put(`${apiUrl}/updateUser/${EmpId}`, formdata)
        .then((res) => {
          Swal.fire(
            "Success!",
            "Employee Updated Successfully!!",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              // Redirect using React Router
              //navigate("/viewUser");
              loade();
              handleCancelEdit();
            }
          });
          hideLoader();
        })
        .catch((err) => {
          console.error(err);
          hideLoader();
          error("Server is Busy !");
        });
    } else {
      setErrors(validationErrors);
    }
  };

  function formatInputDate(dateString) {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h5 className="page-title">Update Employee</h5>
          </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Employee_Id" className="form-label">
                        Employee Id<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="Employee_Id"
                        className="form-control form-control-sm"
                        placeholder="Enter Employee Id"
                        required
                        disabled={!isEditing}
                        value={userDetail.Employee_Id}
                        maxLength={10}
                        onChange={(e) => {
                          const input = e.target.value.toUpperCase();

                          setuUerDetail({
                            ...userDetail,
                            Employee_Id: input,
                          });
                        }}
                      />
                      <p className="text-danger">{errors.Employee_Id}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="txtFname" className="form-label">
                        First Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="txtFname"
                        className="form-control form-control-sm"
                        placeholder="Enter First Name"
                        required
                        disabled={!isEditing}
                        value={userDetail.First_Name}
                        onChange={(e) =>
                          setuUerDetail({
                            ...userDetail,
                            First_Name: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{errors.First_Name}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="txtLname" className="form-label">
                        Last Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="txtLname"
                        className="form-control form-control-sm"
                        placeholder="Enter Last Name"
                        required
                        disabled={!isEditing}
                        value={userDetail.Last_Name}
                        onChange={(e) =>
                          setuUerDetail({
                            ...userDetail,
                            Last_Name: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{errors.Last_Name}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="txtMobile" className="form-label">
                        Mobile<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        maxLength={10}
                        value={userDetail.Mobile}
                        id="txtMobile"
                        disabled={!isEditing}
                        className="form-control form-control-sm"
                        placeholder="Enter Mobile Number"
                        required
                        onChange={(e) => {
                          const input = e.target.value;
                          const numericInput = input.replace(/[^0-9]/g, "");
                          setuUerDetail({
                            ...userDetail,
                            Mobile: numericInput,
                          });
                        }}
                      />
                      <p className="text-danger">{errors.Mobile}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="txtEmail" className="form-label">
                        Email
                      </label>
                      <input
                        type="text"
                        id="txtEmail"
                        disabled={!isEditing}
                        className="form-control form-control-sm"
                        placeholder="Enter Email Id"
                        value={userDetail.Email || ""}
                        onChange={(e) =>
                          setuUerDetail({
                            ...userDetail,
                            Email: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{errors.Email}</p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="Joining_Date" className="form-label">
                        Joining Date<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        id="Joining_Date"
                        className="form-control form-control-sm"
                        required
                        disabled={!isEditing}
                        dateFormat="dd-MM-yyyy"
                        value={formatInputDate(userDetail.Joining_Date)}
                        onChange={(e) =>
                          setuUerDetail({
                            ...userDetail,
                            Joining_Date: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="dropRole" className="form-label">
                        Role
                      </label>
                      <select
                        className="form-control form-control-sm"
                        id="dropRole"
                        disabled={!isEditing}
                        value={userDetail.Role}
                        onChange={(e) =>
                          setuUerDetail({ ...userDetail, Role: e.target.value })
                        }
                      >
                        <option label="Accountant" value="Accountant"></option>
                        <option label="Executive" value="Executive"></option>
                        <option label="Admin" value="Admin"></option>
                        <option label="Director" value="Director"></option>
                        <option label="Manager" value="Manager"></option>
                        <option label="Supervisor" value="Supervisor"></option>
                        <option label="DOP" value="DOP"></option>
                        <option label="Checker" value="Checker"></option>
                        <option label="Ishhuer" value="Ishhuer"></option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="dropGender" className="form-label">
                        Gender
                      </label>
                      <select
                        className="form-control form-control-sm"
                        id="dropGender"
                        value={userDetail.Gender}
                        disabled={!isEditing}
                        onChange={(e) =>
                          setuUerDetail({
                            ...userDetail,
                            Gender: e.target.value,
                          })
                        }
                      >
                        <option label="Male" value="Male"></option>
                        <option label="Female" value="Female"></option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="txtDOB" className="form-label">
                        DOB
                      </label>
                      <input
                        type="date"
                        id="txtDOB"
                        dateFormat="dd-MM-yyyy"
                        className="form-control form-control-sm"
                        disabled={!isEditing}
                        value={formatInputDate(userDetail.DOB)}
                        onChange={(e) =>
                          setuUerDetail({ ...userDetail, DOB: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="Photo">
                        Photo
                      </label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="Photo"
                          name="Photo"
                          accept=".jpg, .jpeg, .png"
                          disabled={!isEditing}
                          onChange={(e) => {
                            const fileName =
                              e.target.files[0]?.name || "Choose file";
                            setPhotoFileName(fileName);

                            setuUerDetail({
                              ...userDetail,
                              Photo: e.target.files[0],
                            });
                          }}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="Photo"
                          style={{ overflow: "hidden" }}
                        >
                          {PhotoFileName}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1" style={{ marginTop: "33px" }}>
                    <div className="mb-3">
                      {userDetail.Photo ? (
                        <>
                          <a style={{ color: "blue" }} href={`${apiUrl}/downloadEmpDocView/` + userDetail.Photo} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                            <Visibility fontSize="medium" />
                          </a> /
                          <a style={{ color: "blue" }} href={`${apiUrl}/downloadEmpDoc/` + userDetail.Photo} className="cursor-pointer">
                            <DownloadIcon fontSize="medium" />
                          </a></>
                      ) : (
                        <><FileDownloadOffIcon /> / <VisibilityOffIcon /></>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="EmpDocument1">
                        Document1
                      </label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="EmpDocument1"
                          name="EmpDocument1"
                          accept=".jpg, .jpeg, .png, .pdf"
                          disabled={!isEditing}
                          onChange={(e) => {
                            const fileName =
                              e.target.files[0]?.name || "Choose file";
                            setEmpDocument1FileName(fileName);

                            setuUerDetail({
                              ...userDetail,
                              EmpDocument1: e.target.files[0],
                            });
                          }}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="EmpDocument1"
                          style={{ overflow: "hidden" }}
                        >
                          {EmpDocument1FileName}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1" style={{ marginTop: "33px" }}>
                    <div className="mb-3">
                      {userDetail.EmpDocument1 ? (
                        <>
                          <a style={{ color: "blue" }} href={`${apiUrl}/downloadEmpDocView/` + userDetail.EmpDocument1} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                            <Visibility fontSize="medium" />
                          </a> /
                          <a style={{ color: "blue" }} href={`${apiUrl}/downloadEmpDoc/` + userDetail.EmpDocument1} className="cursor-pointer">
                            <DownloadIcon fontSize="medium" />
                          </a></>
                      ) : (
                        <><FileDownloadOffIcon /> / <VisibilityOffIcon /></>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="EmpDocument2">
                        Document2
                      </label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="EmpDocument2"
                          name="EmpDocument2"
                          accept=".jpg, .jpeg, .png, .pdf"
                          disabled={!isEditing}
                          onChange={(e) => {
                            const fileName =
                              e.target.files[0]?.name || "Choose file";
                            setEmpDocument2FileName(fileName);

                            setuUerDetail({
                              ...userDetail,
                              EmpDocument2: e.target.files[0],
                            });
                          }}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="EmpDocument2"
                          style={{ overflow: "hidden" }}
                        >
                          {EmpDocument2FileName}
                        </label>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="col-md-1" style={{ marginTop: "33px" }}>
                    <div className="mb-3">
                      {userDetail.EmpDocument2 ? (
                        <>
                          <a style={{ color: "blue" }} href={`${apiUrl}/downloadEmpDocView/` + userDetail.EmpDocument2} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                            <Visibility fontSize="medium" />
                          </a> /
                          <a style={{ color: "blue" }} href={`${apiUrl}/downloadEmpDoc/` + userDetail.EmpDocument2} className="cursor-pointer">
                            <DownloadIcon fontSize="medium" />
                          </a></>
                      ) : (
                        <><FileDownloadOffIcon /> / <VisibilityOffIcon /></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label htmlFor="txtAddress" className="form-label">
                        Address
                      </label>
                      <textarea
                        type="text"
                        id="txtAddress"
                        className="form-control"
                        placeholder="Enter Address"
                        disabled={!isEditing}
                        value={userDetail.Address}
                        onChange={(e) =>
                          setuUerDetail({
                            ...userDetail,
                            Address: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12 text-center">
                    {!isEditing && (
                      <button
                        className="btn btn-primary mt-2"
                        onClick={handleEditClick}
                      >
                        Edit
                      </button>
                    )}{" "}
                    &nbsp;
                    {!isEditing && (
                      <button
                        className="btn btn-success mt-2"
                        style={{ display: type === "edit" ? "none" : "" }}
                        onClick={(e) => {
                          e.preventDefault();
                          insertOTP(e);
                        }}
                      >
                        Verify
                      </button>
                    )}
                    {isEditing && (
                      <button
                        className="btn btn-primary mt-2"
                        onClick={handleCancelEdit}
                      >
                        Close
                      </button>
                    )}
                    &nbsp;
                    {isEditing && (
                      <button className="btn btn-primary mt-2" type="submit">
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          {isModal && (
            <OTP
              open={isModal}
              EmpId={EmpId}
              onClose={closed}
              role={userData?.role}
              name={`${userDetail.First_Name} ${userDetail.Last_Name}`}
              type={type}
            />
          )}
          {loader}
        </div>
      </div>
    </div>
  );
};
export default UpdateUser;
