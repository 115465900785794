import React from 'react';

const TopupAgreement = () => {
    return (
        <div style={{ textAlign: 'center', padding: '20px', fontSize: '18px', fontWeight: 'bold', color: '#333' }}>
            Top-up Agreement Coming Soon
        </div>
    );
};

export default TopupAgreement;
