import * as React from "react";
import * as XLSX from "xlsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../componets/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { mdiFileExcel } from "@mdi/js";
import { apiUrl } from "../../componets/apiConfig";
import DataTable from "react-data-table-component";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { formatDate } from "../../mui/dateFormate";

const AddProduct = () => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [Plan_Id, setPlanId] = useState(null);
    const [Interest_Rate, setInterestRate] = useState("");
    const [Bank, setBankOption] = useState([]);
    const [Bank_Name, setBankName] = useState("");
    const [Acc_No, setAccNo] = useState("");
    const [Branch_Name, setBranchName] = useState("");
    const [Acc_Holder_Name, setAccHolderName] = useState("");
    const [IFSC_Code, setIFSCCode] = useState("");
    const [Capital_Amount, setCapitalAmount] = useState("");
    const [Description, setDescription] = useState("");
    const [data, setData] = useState([]);
    const [records, setRecords] = useState([]);
    const [editingItem, setEditingItem] = useState(null);
    const [error, setError] = useState("");
    const { userData } = useUser();
    const [Plan_Name, setPlanName] = useState("");
    const [Plan_Prefix, setPlanPrefix] = useState("");
    const [Payout_Int_Date, setPayoutIntDate] = useState("");
    const [Last_Payable_Int_days, setLastPayableIntdays] = useState("1");
    const [Capital_Return_Days, setCapitalReturnDays] = useState("30");
   
    const [Filter, setFilter] = useState("");

 

    const [customer, setCustomer] = useState({
        Lender_PreName: "Mr.",
        Lender_Name: "",
        Lender_Registered_Mobile_No: "",
        Lender_Email_ID: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomer((prevCustomer) => ({
            ...prevCustomer,
            [name]: value,
        }));
    };


    const columns = [
  
        {
            name: "Date",
            selector: (row) => formatDate(row.Added_On),
            sortable: true,
        },
        {
            name: "Customer",
            selector: (row) => `${row.Lender_PreName} ${row.Lender_Name}`,
            sortable: true,
        },
            {
            name: "Mobile",
                selector: (row) => `${row.Lender_Registered_Mobile_No}`,
            sortable: true,
        },

    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: "30px",
            },
        },
    };

    useEffect(() => {
        if (userData && userData.Org_Id) {
            Load();
        }
    }, [userData, Filter]);

    const Load = async () => {
        showLoader();

        const result = await axios.get(
            `${apiUrl}/getSentAgreement?Filter=${Filter}`
        );
        const sortedData = result.data;
        setData(sortedData);
        setRecords(sortedData);
        console.log(sortedData)
        hideLoader();

    };

    async function save(event) {
        event.preventDefault();

        const error = {};
      const updatedCustomer = {
        ...customer,
          User_Role: userData?.role,
          Added_By_Employee_Id: userData?.Employee_Id ? userData?.Employee_Id : "",
          Added_By_Id: userData.userId,
          Added_By: `${userData?.firstName} ${userData?.lastName}`,
          Org_Id: userData?.Org_Id,
          UserName: userData?.UserName,
      };
    
        if (Object.keys(error).length === 0) {
            try {
                showLoader();
                await axios.post(`${apiUrl}/SendAgreement`, updatedCustomer);
                Swal.fire("Success!", "Agreement Sent successfully", "success");
                setError("");
            setCustomer({
        Lender_PreName: "Mr.",
        Lender_Name: "",
        Lender_Registered_Mobile_No: "",
        Lender_Email_ID: "",
    })
                Load();
                hideLoader();
            } catch (err) {
                Swal.fire({
                    title: "Error",
                    text: "Failed to send Agreement. Please try again later.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                hideLoader();
            }
        } else {
            setError(error);
        }
    }




    const DownloadPDF = () => {
        const doc = new jsPDF();
        doc.text("Agreement Sent", 20, 10);

        const columns = [
            "Date",
            "Lender Name",
            "Mobile"
        ];

        const rows = records.map((row) => [
            formatDate(row.Added_On),
            `${row.Lender_PreName} ${row.Lender_Name}`,
            `${row.Lender_Registered_Mobile_No}`
        ]);

        doc.autoTable({
            head: [columns],
            body: rows,
        });

        doc.save("Agreement Sent.pdf");
    };

    const DownloadExcel = () => {
        const columns = [
            "Date",
            "Lender Name",
            "Mobile"
        ];
        const rows = [columns];
        records.forEach((row) => {
            rows.push([
                formatDate(row.Added_On),
                `${row.Lender_PreName} ${row.Lender_Name}`,
                `${row.Lender_Registered_Mobile_No}`
            ]);
        });

        const workSheet = XLSX.utils.aoa_to_sheet(rows);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Agreement Sent");


        // Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

        // Download
        XLSX.writeFile(workBook, "Agreement Sent.xlsx");
    };



    function handleFilter(event) {
        const filterValue = event.target.value.toLowerCase();
        const filteredData = data?.filter((row) => {
            return (
                (row.Lender_Name && `${row?.Lender_PreName} ${row?.Lender_Name}`.toLowerCase().includes(filterValue)) ||
                (row.Added_On && formatDate(row?.Added_On).toLowerCase().includes(filterValue)) ||
                (row.Lender_Registered_Mobile_No && row?.Lender_Registered_Mobile_No?.toLowerCase().includes(filterValue))
            );
        });
        setRecords(filteredData);
    }


    function formatInputDate(dateString) {
        if (!dateString) {
            return "";
        }

        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
    }



    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
                        <h5 className="page-title">Send Agreement</h5>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <Box height={10} />
                            <Stack>
                                <form onSubmit={save}>
                                    <div className="row mb-4">



                                        <div className="col-md-3 mb-3">
                                            <label>Lender Initial<span style={{ color: "red" }}>*</span></label>
                                            <select
                                                name="Lender_PreName"
                                                value={customer.Lender_PreName}
                                                onChange={handleChange}
                                                className="form-control"
                                                required
                                            >
                                                <option value="Mr.">Mr.</option>
                                                <option value="Miss.">Miss.</option>
                                                <option value="Mrs.">Mrs.</option>
                                                <option value="Dr.">Dr.</option>
                                                {/* Add more options if needed */}
                                            </select>
                                        </div>

                                        <div className="col-md-9 mb-3">
                                            <label>Lender Name<span style={{ color: "red" }}>*</span></label>
                                            <input
                                                type="text"
                                                name="Lender_Name"
                                                required
                                                value={customer.Lender_Name}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label>Lender Mobile Number<span style={{ color: "red" }}>*</span></label>
                                            <input
                                                type="text"
                                                required
                                                name="Lender_Registered_Mobile_No"
                                                maxLength={10}
                                                value={customer?.Lender_Registered_Mobile_No}
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    const numericInput = input.replace(/[^0-9]/g, "");
                                                    setCustomer({
                                                        ...customer,
                                                        Lender_Registered_Mobile_No: numericInput
                                                    })


                                                }}
                                                className="form-control"

                                            />
                                        </div>


                                        <div className="col-md-6 mb-3">
                                            <label>Lender Email ID<span style={{ color: "red" }}>*</span></label>
                                            <input
                                                type="email"
                                                name="Lender_Email_ID"
                                                required
                                                value={customer.Lender_Email_ID}
                                                onChange={handleChange}
                                                className="form-control"

                                            />
                                        </div>



                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12 text-center">
                                            <button className="btn btn-primary mt-2" type="submit">
                                                Submit
                                            </button>
                                        </div>
                                    </div>

                                </form>
                            </Stack>
                         
                        </div>
                    </div>
                    <div className="card mt-2">
                        <div className="card-body">
                            <Box height={10} />

                            <div className="row">
                                <div className="col-md-6">
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        className="mx-2 my-2 mb-2"
                                        sx={{ zIndex: 0, position: "relative" }}
                                    >
                                        <TextField
                                            onChange={handleFilter}
                                            size="small"
                                            label="Search..."
                                        />
                                        <div>
                                            <Tooltip title="Export to PDF">
                                                <i
                                                    style={{
                                                        cursor: "pointer",
                                                        padding: "5px",
                                                    }}
                                                    onClick={() => {
                                                        DownloadPDF();
                                                    }}
                                                >
                                                    <PictureAsPdfIcon />
                                                </i>
                                            </Tooltip>

                                            <Tooltip title="Export to Excel">
                                                <i
                                                    style={{
                                                        padding: "5px",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        DownloadExcel();
                                                    }}
                                                >
                                                    {" "}
                                                    <Icon path={mdiFileExcel} size={1} />
                                                </i>
                                            </Tooltip>
                                        </div>
                                    </Stack>
                                </div>

                                <div className="col-md-6">
                                    <div className="row d-flex justify-content-end mb-2 ml-1">
                                        <div className="col-md-4">
                                            {/* <label className="form-label" htmlFor="example-select">
                                                Filter By Board:
                                            </label> */}

                                            <select
                                                className="form-control"
                                                id="example-select"
                                                onChange={(e) => setFilter(e.target.value)}
                                            >
                                                <option value={""}>All</option>
                                                <option value={"This Month"}>This Month</option>
                                                <option value={"Prev Month"}>Prev Month</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Box height={14} />
                            <div style={{ zIndex: 0, position: "relative" }}>
                                <DataTable
                                    columns={columns}
                                    data={records}
                                    customStyles={customStyles}
                                    fixedHeader
                                    pagination
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            {loader}
        </div>
    );
};

export default AddProduct;
