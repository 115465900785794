import React from 'react';

const RenewalWithTopup = () => {
    return (
        <div style={{ textAlign: 'center', padding: '20px', fontSize: '18px', fontWeight: 'bold', color: '#333' }}>
            Renewal with Top-up Agreement Coming Soon
        </div>
    );
};

export default RenewalWithTopup;
