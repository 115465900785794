import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import useFullPageLoader from "../../mui/useFullPageLoader";
import FileDownloadOffIcon from "@mui/icons-material/LinkOff";
import DownloadIcon from "@mui/icons-material/Visibility";
import dayjs from 'dayjs';
import { IconButton } from '@mui/material';
import { apiUrl } from "../../componets/apiConfig";
import AgreementContent from './AgreementContent';
import { formatDate, formatInputDate } from "../../mui/dateFormate";
import html2pdf from "html2pdf.js";
import axios from "axios";
import "../../CSS/agreement.css";

function formatTime(timeString) {
    try {
        if (!timeString || timeString === 'null') return '';
        const [hours, minutes, seconds = '00'] = timeString.split(":").map(num => num.padStart(2, '0'));
        const date = new Date();
        date.setHours(parseInt(hours, 10));
        date.setMinutes(parseInt(minutes, 10));
        date.setSeconds(parseInt(seconds, 10));
        const formattedTime = date.toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }).toUpperCase();
        return formattedTime;
    } catch (error) {
        console.error("Error formatting time:", error);
        return "Invalid Time";
    }
}

const AgreementPreview = ({ open, close, customer, status, transactionDetails, PayInBank, PayOutBank }) => {
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [hr, setHr] = useState(true);

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        close(false);
    };

    const handleSubmit = () => {
        // handleGeneratePDF()
        setShow(false);
        close(true);
        // generateSavePDF();
    };

    const handleGeneratePDF = () => {
        const element = document.getElementById("printablediv");
        const options = {
            filename: 'Fresh_Agreement.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait', 
                margins: { top: 100, left: 15, bottom: 100, right: 15 }
             },
            pagebreak: { mode: ['css', 'legacy'] }
        };

        html2pdf().from(element).set(options).toPdf().get('pdf').then((pdf) => {
            const totalPages = 9;
            while (pdf.internal.pages.length < totalPages) {
                pdf.addPage();
            }
        }).save();
    };

    const generateSavePDF = async () => {
        const element = document.getElementById("printablediv");
        const opt = {
            margin: [10, 15, 10, 15],
            filename: 'Fresh_Agreement.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 1.9 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            pagebreak: { mode: ['css', 'legacy'] }
        };

        try {
            const pdfBlob = await html2pdf().from(element).set(opt).outputPdf("blob");


            //console.log(whatsapp);
            showLoader();

            const formData = new FormData();
            formData.append("Agreement_Doc", pdfBlob, "Fresh_Agreement.pdf");

            await axios.put(`/saveAgreementDoc/${customer?.id}`, formData);

            hideLoader();
            handleClose();

        } catch (error) {
            // Handle any errors
            console.error("Error saving PDF:", error);
            hideLoader();
        }
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Agreement Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="container">




                    <div className="div" >
                        {/* Content divided across pages */}
                        <div className="row justify-content-center" id="printablediv" style={{ padding: "10px", color: "#000" }} >
                            <AgreementContent customer={customer} formatDate={formatDate} formatTime={formatTime} 
                            transactionDetails={transactionDetails} PayInBank={PayInBank} PayOutBank={PayOutBank} />
                            
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-2">
                        <button type="submit" className="btn btn-primary mb-1"
                            onClick={status === "1" ? generateSavePDF : handleSubmit}
                        // onClick={handleGeneratePDF}
                        >
                            {status === "1" ? 'Print' : 'Submit'}
                        </button>
                    </div>

                </div>
                {loader}
            </Modal.Body>
        </Modal >
    );
}

export default AgreementPreview;
