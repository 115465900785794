import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiUrl } from "../../componets/apiConfig";
import { useParams, useNavigate } from "react-router-dom";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { useUser } from "../../componets/UserContext";
import { formatDate, formatInputDate, calculateEndDate } from "../../mui/dateFormate";
import Swal from "sweetalert2";
import AgreementPreview from "./AgreementPreview"
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DownloadIcon from "@mui/icons-material/Download";
import Visibility from "@mui/icons-material/Visibility";
import "../../CSS/agreement.css";

const AgreementFormNew = ({ customer, setCustomer, Id, transactionDetails, setTransactionDetails, PayInBank, setPayInBank, PayOutBank, setPayOutBank }) => {
    const { userData } = useUser();
    const [Bank_DetailsFileName, setBank_DetailsFileName] = useState("Choose file");
    const [Payment_ProofFileName, setPayment_ProofFileName] = useState("Choose file");
    const [Aadhar_CardFileName, setAadhar_CardFileName] = useState("Choose file");
    const [Pan_CardFileName, setPan_CardFileName] = useState("Choose file");
    const [Attachment1FileName, setAttachment1FileName] = useState("Choose file");
    const [Attachment2FileName, setAttachment2FileName] = useState("Choose file");
    const [bankData, setBankData] = useState([]);
    useEffect(() => {
        if (userData && userData.Org_Id) {
            Load();
        }
    }, [userData]);

    const Load = async () => {
        const result = await axios.get(
            `${apiUrl}/getBorrowerBanks?OrgId=${userData.Org_Id}`
        );
        const sortedData = result.data.sort((a, b) => b.Sr_No - a.Sr_No);
        setBankData(sortedData);
        return result;
    };
    const handleChange = (e) => {
        const { name, value } = e.target;

        // Update the Pay_In_Bank_Count and dynamically set bank detail rows
        if (name === "Pay_In_Bank_Count") {
            const count = parseInt(value);
            setCustomer(prev => ({ ...prev, [name]: count }));

            // Adjust the PayInBank array length based on the count selected
            setPayInBank(prev => {
                const newArray = Array.from({ length: count }, (_, i) => prev[i] || {
                    PayIn_Bank_Account_Holder_Name: '',
                    PayIn_Bank_Name: '',
                    PayIn_Account_Number: '',
                    PayIn_IFSC_Code: '',
                    PayIn_Branch: '',
                    PayIn_Account_Holder_Type: '',
                    PayIn_Account_Type: ''
                });
                return newArray.slice(0, count);  // Truncate array if count is reduced
            });
        } else if (name === "Pay_Out_Bank_Count") {
            const count = parseInt(value);
            setCustomer(prev => ({ ...prev, [name]: count }));

            // Adjust the PayOutBank array length based on the count selected
            setPayOutBank(prev => {
                const newArray = Array.from({ length: count }, (_, i) => prev[i] || {
                    PayOut_Bank_Account_Holder_Name: '',
                    PayOut_Bank_Name: '',
                    PayOut_Account_Number: '',
                    PayOut_IFSC_Code: '',
                    PayOut_Branch: '',
                    PayOut_Account_Holder_Type: '',
                    PayOut_Account_Type: ''
                });
                return newArray.slice(0, count);  // Truncate array if count is reduced
            });
        } else {
            setCustomer(prev => ({ ...prev, [name]: value }));
        }
    };
    const handlePayInBankDetailChange = (index, e) => {
        const { name, value } = e.target;

        // Update specific bank detail entry
        setPayInBank(prev =>
            prev.map((bank, i) => i === index ? { ...bank, [name]: value } : bank)
        );
    };
    const handlePayOutBankDetailChange = (index, e) => {
        const { name, value } = e.target;

        // Update specific bank detail entry
        setPayOutBank(prev =>
            prev.map((bank, i) => i === index ? { ...bank, [name]: value } : bank)
        );
    };
    const convertNumberToWords = (num) => {
        const a = [
            "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten",
            "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"
        ];
        const b = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

        const n = (number) => {
            if (number < 20) return a[number];
            const digit = number % 10;
            return b[Math.floor(number / 10)] + (digit ? " " + a[digit] : "");
        };

        const convertToWords = (num) => {
            if (num === 0) return "Zero";

            let crore = Math.floor(num / 10000000);
            num %= 10000000;
            let lakh = Math.floor(num / 100000);
            num %= 100000;
            let thousand = Math.floor(num / 1000);
            num %= 1000;
            let hundred = Math.floor(num / 100);
            num %= 100;
            let rest = num;

            let result = "";

            if (crore > 0) result += `${n(crore)} Crore `;
            if (lakh > 0) result += `${n(lakh)} Lakh `;
            if (thousand > 0) result += `${n(thousand)} Thousand `;
            if (hundred > 0) result += `${n(hundred)} Hundred `;
            if (rest > 0) result += n(rest);

            return result.trim();
        };

        return convertToWords(parseInt(num, 10));
    };

    const paymentOptions = [
        { label: "Cash", value: "Cash" },
        { label: "Cheque", value: "Cheque" },
        { label: "DD", value: "DD" },
        { label: "GPAY", value: "GPAY" },
        { label: "IMPS", value: "IMPS" },
        { label: "NEFT", value: "NEFT" },
        { label: "Online", value: "Online" },
        { label: "PAYTM", value: "PAYTM" },
        { label: "PhonePay", value: "PhonePay" },
        { label: "RTGS", value: "RTGS" },
        { label: "TPT", value: "TPT" }

    ];

    useEffect(() => {
        // If Transaction_Type is reduced, truncate the transactionDetails array
        if (customer.Transaction_Type < transactionDetails.length) {
            setTransactionDetails((prevDetails) => prevDetails.slice(0, customer.Transaction_Type));
        }
    }, [customer.Transaction_Type]);
    const handleTransactionChange = (index, field, value) => {
        const updatedDetails = [...transactionDetails];
        if (field === "Bank") {
            const selectedBank = bankData.find(bank => bank.Sr_No === Number(value));
            updatedDetails[index] = {
                ...updatedDetails[index],
                Bank_ID: selectedBank ? selectedBank.Sr_No : "",
                Bank_Name: selectedBank ? selectedBank.Bank_Name : ""
            };
        } else {
            updatedDetails[index] = {
                ...updatedDetails[index],
                [field]: value
            };
        }
        setTransactionDetails(updatedDetails);
    };
    function clearTransactionFields(mode, index, field) {
        const updatedDetails = [...transactionDetails];
        // Clear bank details if mode is "Cash"
        if (mode === "Cash") {
            updatedDetails[index] = {
                ...updatedDetails[index],
                Bank_ID: "",
                Bank_Name: "",
            };
        }
        // Clear Cash_Received_By and Cash_Handovered_To if mode is neither "Cash" nor "Online"
        else if (mode !== "Cash" ) {
            updatedDetails[index] = {
                ...updatedDetails[index],
                Cash_Received_By: "",
                Cash_Handovered_To: "",
            };
        }
        updatedDetails[index][field] = mode;
        recalculateCounts(updatedDetails);
        setTransactionDetails(updatedDetails)
    }


    const renderTransactionRows = () => {
        const rows = [];
        for (let i = 0; i < customer.Transaction_Type; i++) {
            rows.push(
                <tr key={i}>
                    <td>
                        <input
                            type="text"
                            name={`Amount${i + 1}`}
                            required
                            value={transactionDetails[i]?.Amount || ""}
                            onChange={(e) =>
                                handleTransactionChange(i, "Amount", e.target.value)
                            }
                            className="form-control form-agreement-field"
                        />
                    </td>
                    <td>
                        <select
                            className="form-control form-agreement-field"
                            name={`Mode_of_Payment${i + 1}`}
                            required
                            value={transactionDetails[i]?.Mode_of_Payment || ""}
                            onChange={(e) => {
                                // handleTransactionChange(i, "Mode_of_Payment", e.target.value);
                                clearTransactionFields(e.target.value, i, "Mode_of_Payment");
                            }}
                        >
                            <option value="">---Select---</option>
                            {paymentOptions.map((option, index) => (
                                <option key={index} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </td>
                    <td style={{ maxWidth: "130px" }}>
                        <input
                            type="date"
                            name={`Received_Date${i + 1}`}
                            required
                            value={transactionDetails[i]?.Received_Date || ""}
                            onChange={(e) =>
                                handleTransactionChange(i, "Received_Date", e.target.value)
                            }
                            className="form-control form-agreement-field"
                        />
                    </td>
                    <td style={{ maxWidth: "130px" }}>
                        <input
                            type="date"
                            name={`Start_Date${i + 1}`}
                            required
                            value={transactionDetails[i]?.Start_Date || ""}
                            onChange={(e) =>
                                handleTransactionChange(i, "Start_Date", e.target.value)
                            }
                            className="form-control form-agreement-field"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            name={`Transaction_ID${i + 1}`}
                            required
                            value={transactionDetails[i]?.Transaction_ID || ""}
                            onChange={(e) =>
                                handleTransactionChange(i, "Transaction_ID", e.target.value)
                            }
                            className="form-control form-agreement-field"
                        />
                    </td>
                    <td>
                        <select
                            className="form-control form-agreement-field"
                            name={`Bank${i + 1}`}
                            required
                            value={transactionDetails[i]?.Bank_ID || ""}
                            disabled={transactionDetails[i]?.Mode_of_Payment === "Cash"}
                            onChange={(e) => {
                                handleTransactionChange(i, "Bank", e.target.value);
                            }}
                        >
                            <option value="">---Select---</option>
                            {bankData.map((option, index) => (
                                <option key={index} value={option.Sr_No}>
                                    {option.Bank_Name}
                                </option>
                            ))}
                        </select>
                    </td>
                    <td>
                        <input
                            type="text"
                            name={`Cash_Received_By${i + 1}`}
                            required
                            value={transactionDetails[i]?.Cash_Received_By || ""}
                            onChange={(e) =>
                                handleTransactionChange(i, "Cash_Received_By", e.target.value)
                            }
                            disabled={
                                transactionDetails[i]?.Mode_of_Payment !== "Cash" 
                            }
                            className="form-control form-agreement-field"
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            name={`Cash_Handovered_To${i + 1}`}
                            required
                            value={transactionDetails[i]?.Cash_Handovered_To || ""}
                            onChange={(e) =>
                                handleTransactionChange(i, "Cash_Handovered_To", e.target.value)
                            }
                            disabled={
                                transactionDetails[i]?.Mode_of_Payment !== "Cash" 
                            }
                            className="form-control form-agreement-field"
                        />
                    </td>
                </tr>
            );
        }
        return rows;
    };
    const numberOptions = Array.from({ length: 10 }, (_, i) => (i + 1) * 6);
    const recalculateCounts = (details) => {
        debugger;
        let cash = 0;
        let online = 0;
        let bank = 0;

        details.forEach((transaction) => {
            if (transaction.Mode_of_Payment === "Cash") {
                cash++;
            } else if (
                ["Online", "PAYTM", "PhonePay", "GPAY"].includes(transaction.Mode_of_Payment)
            ) {
                online++;
            } else if (
                ["Cheque", "DD", "IMPS", "NEFT", "RTGS", "TPT"].includes(transaction.Mode_of_Payment)
            ) {
                bank++;
            }
        });
        if(bank === 0)
        {
            setPayInBank([{
                PayIn_Bank_Account_Holder_Name: '',
                PayIn_Bank_Name: '',
                PayIn_Account_Number: '',
                PayIn_IFSC_Code: '',
                PayIn_Branch: '',
                PayIn_Account_Holder_Type: '',
                PayIn_Account_Type: ''
            }]);
            setPayOutBank([{
                PayOut_Bank_Account_Holder_Name: '',
                PayOut_Bank_Name: '',
                PayOut_Account_Number: '',
                PayOut_IFSC_Code: '',
                PayOut_Branch: '',
                PayOut_Account_Holder_Type: '',
                PayOut_Account_Type: ''
            }]);
        }
        setCustomer({
            ...customer,
            cashCount: cash,
            onlineCount: online,
            bankCount: bank
        })
    };

    return (
        <div className="row">

            {/* <div className="col-md-3 mb-3">
                                    <label className="field_Label" >Date</label>
                                    <input
                                        type="date"
                                        name="AgDate"
                                        required
                                        value={formatInputDate(customer.AgDate || customer.Date)}
                                        onChange={handleChange}
                                        className="form-control form-agreement-field"
                                    />
                                </div>


                                <div className="col-md-9 mb-3">
                                    <label className="field_Label" >Location</label>
                                    <input
                                        type="text"
                                        name="Location"
                                        required
                                        value={customer.Location}
                                        onChange={handleChange}
                                        className="form-control form-agreement-field"
                                    />
                                </div> */}

            <h6 className="col-md-12 mt-3"><strong>Agreement Details: </strong></h6>
            <div className="col-md-3 mb-3">
                <label className="field_Label" >Date</label>
                <input
                    type="date"
                    name="AgDate"
                    required
                    value={formatInputDate(customer.AgDate || customer.Date)}
                    onChange={handleChange}
                    className="form-control form-agreement-field"
                />
            </div>
            <div className="col-md-3 mb-3">
                <label className="field_Label" >Loan Amount (Number)</label>
                <input
                    type="text"
                    name="Loan_Amount_num"
                    required
                    value={customer?.Loan_Amount_num}
                    onChange={(e) => {
                        const input = e.target.value;
                        const decimalInput = input.replace(/[^0-9.]/g, "");
                        const amount = decimalInput.replace(
                            /^(\d*\.\d*)\..*$/,
                            "$1"
                        );
                        setCustomer({
                            ...customer,
                            Loan_Amount_num: amount,
                            Loan_Amount_txt: convertNumberToWords(amount),
                            Penalty_Amount: (amount * customer.Penalty_Percent) / 100,
                        });

                    }}
                    className="form-control form-agreement-field"
                />
            </div>
            <div className="col-md-6 mb-3">
                <label className="field_Label" >Loan Amount (Text)</label>
                <input
                    type="text"
                    required disabled
                    name="Loan_Amount_txt"
                    value={customer.Loan_Amount_txt}
                    onChange={handleChange}
                    className="form-control form-agreement-field"
                />
            </div>

            <div className="col-md-3 mb-3">
                <label className="field_Label" >Loan Tenure</label>
                <select
                    className="form-control form-agreement-field"
                    name="Tenure"
                    required
                    value={customer.Tenure}
                    onChange={(e) => {
                        setCustomer({
                            ...customer,
                            Tenure: e.target.value,
                            Loan_End_Date: calculateEndDate(customer.Loan_Start_Date, e.target.value)
                        });
                    }}
                >
                    <option value="Six Months">Six Months</option>
                    <option value="One Year">One Year</option>
                </select>
            </div>

            {/* Loan Dates */}
            <div className="col-md-3 mb-3">
                <label className="field_Label" >Loan Start Date</label>
                <input
                    type="date"
                    required
                    name="Loan_Start_Date"
                    value={customer.Loan_Start_Date}
                    onChange={(e) => {
                        setCustomer({
                            ...customer,
                            Loan_Start_Date: e.target.value,
                            Loan_End_Date: calculateEndDate(e.target.value, customer.Tenure)
                        });
                    }}
                    className="form-control form-agreement-field"
                />
            </div>

            <div className="col-md-3 mb-3">
                <label className="field_Label" >Loan End Date</label>
                <input
                    type="date" disabled
                    required
                    name="Loan_End_Date"
                    value={customer.Loan_End_Date}
                    onChange={handleChange}
                    className="form-control form-agreement-field"
                />
            </div>
            <div className="col-md-3 mb-3">
                <label className="field_Label" >Transaction Type</label>
                <select className="form-control form-agreement-field"
                    name="Transaction_Type" required
                    value={customer.Transaction_Type}
                    onChange={(e) => {
                        setCustomer({
                            ...customer,
                            Transaction_Type: e.target.value
                        });
                        //clearTransaction(e.target.value);
                    }}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                        <option key={num} value={num}>
                            {num}
                        </option>
                    ))}
                </select>
            </div>
            {/* Mode of Payment */}
            <div className="col-md-12 mb-3">
                <table
                    className="table align-items-center agreementTable"
                    style={{ width: "100%" }}
                >
                    <thead>
                        <tr>
                            <th>Amount</th>
                            <th>Mode</th>
                            <th>Received Date</th>
                            <th>Start Date</th>
                            <th>Transaction ID/ Receipt ID</th>
                            <th>Bank</th>
                            <th>Cash Received By</th>
                            <th>Cash Handovered To</th>
                        </tr>
                    </thead>
                    <tbody>{renderTransactionRows()}</tbody>
                </table>
            </div>
            <div className="col-md-3 mb-3">
                <label className="field_Label" >Loan Disbursal Date</label>
                <input
                    type="date"
                    required
                    name="Loan_Disbursal_Date"
                    value={customer.Loan_Disbursal_Date}
                    onChange={handleChange}
                    className="form-control form-agreement-field"
                />
            </div>
            <div className="col-md-3 mb-3">
                <label className="field_Label" >Total Loan Amount</label>
                <input
                    type="text"
                    name="Total_Loan_Amount"
                    required
                    value={customer.Total_Loan_Amount}
                    onChange={(e) => {
                        const input = e.target.value;
                        const decimalInput = input.replace(/[^0-9.]/g, "");
                        const amount = decimalInput.replace(
                            /^(\d*\.\d*)\..*$/,
                            "$1"
                        );
                        setCustomer({
                            ...customer,
                            Total_Loan_Amount: amount,
                        });

                    }}
                    className="form-control form-agreement-field"
                />
            </div>

            {/* KYC Date & Time */}
            <div className="col-md-3 mb-3">
                <label className="field_Label" >KYC Date</label>
                <input
                    type="date"
                    name="KYC_Date"
                    required
                    value={customer.KYC_Date}
                    onChange={handleChange}
                    className="form-control form-agreement-field"
                />
            </div>

            <div className="col-md-3 mb-3">
                <label className="field_Label" >KYC Time</label>
                <input
                    type="time"
                    name="KYC_Time"
                    required
                    value={customer.KYC_Time}
                    onChange={handleChange}
                    className="form-control form-agreement-field"
                />
            </div>

            {/* Tenure */}
            <div className="col-md-4 mb-3">
                <label className="field_Label" >Lock In Tenure</label>
                <select
                    className="form-control form-agreement-field"
                    name="LockIn_Tenure"
                    required
                    value={customer.LockIn_Tenure}
                    onChange={(e) => {
                        setCustomer({
                            ...customer,
                            LockIn_Tenure: e.target.value,
                            LockIn_Period_To_Date: calculateEndDate(customer.LockIn_Period_From_Date, e.target.value)
                        });
                    }}

                >
                    <option value="Three Months">Three Months</option>
                    <option value="Six Months">Six Months</option>
                    <option value="One Year">One Year</option>
                </select>
            </div>
            {/* Lock-in Period From Date */}
            <div className="col-md-4 mb-3">
                <label className="field_Label" >Lock-In Period From Date</label>
                <input
                    type="date"
                    name="LockIn_Period_From_Date"
                    required
                    value={customer.LockIn_Period_From_Date}
                    onChange={(e) => {
                        setCustomer({
                            ...customer,
                            LockIn_Period_From_Date: e.target.value,
                            LockIn_Period_To_Date: calculateEndDate(e.target.value, customer.LockIn_Tenure)
                        });
                    }}
                    className="form-control form-agreement-field"
                />
            </div>

            {/* Lock-in Period To Date */}
            <div className="col-md-4 mb-3">
                <label className="field_Label" >Lock-In Period To Date</label>
                <input
                    type="date" disabled
                    name="LockIn_Period_To_Date"
                    required
                    value={customer.LockIn_Period_To_Date}
                    onChange={handleChange}
                    className="form-control form-agreement-field"
                />
            </div>
            <div className="col-md-4 mb-3">
                <label className="field_Label" >Penalty Percent</label>
                <select className="form-control form-agreement-field"
                    name="Penalty_Percent" required value={customer.Penalty_Percent}
                    onChange={(e) => {
                        setCustomer({
                            ...customer,
                            Penalty_Percent: e.target.value,
                            Penalty_Amount: (parseFloat(customer.Loan_Amount_num) * parseFloat(e.target.value) / 100).toFixed(2),
                        });
                    }}>
                    {numberOptions.map((num, index) => (
                        <option key={index + paymentOptions.length} value={num}>
                            {num}%
                        </option>
                    ))}
                </select>
            </div>
            <div className="col-md-4 mb-3">
                <label className="field_Label" >Lost Adjustment Penalty</label>
                <input type="text" disabled name="Penalty_Amount"
                    required value={customer.Penalty_Amount}
                    onChange={handleChange} className="form-control form-agreement-field"
                />
            </div>
           {/*  <h6 className="col-md-12"><strong>Borrower Details:</strong></h6> */}
           

            <div className="col-md-4 mb-3">
                <label className="field_Label" >Borrower Mobile Number</label>
                <input
                    type="text"
                    required
                    name="Borrower_Mobile"
                    value={customer.Borrower_Mobile}
                    onChange={handleChange}
                    className="form-control form-agreement-field"

                />
            </div>
            <h6 className="col-md-12"><strong>{customer?.No_of_Lender === "1" ? 'Lender Details: ' : 'First Lender Details: '}</strong></h6>


            <div className="col-md-2 mb-3">
                <label className="field_Label" >No Of Lender</label>
                <select
                    name="No_of_Lender"
                    value={customer?.No_of_Lender}
                    onChange={handleChange}
                    className="form-control form-agreement-field"
                    required
                >
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>

                </select>
            </div>

            <div className="col-md-2 mb-3">
                <label className="field_Label" >Lender Initial </label>
                <select
                    name="Lender_PreName"
                    value={customer.Lender_PreName}
                    onChange={handleChange}
                    className="form-control form-agreement-field"
                    required
                >
                    <option value="Mr.">Mr.</option>
                    <option value="Miss.">Miss.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Dr.">Dr.</option>
                    {/* Add more options if needed */}
                </select>
            </div>

            <div className="col-md-8 mb-3">
                <label className="field_Label" >Lender Name</label>
                <input
                    type="text"
                    name="Lender_Name"
                    required
                    value={customer.Lender_Name}
                    onChange={handleChange}
                    className="form-control form-agreement-field"
                />
            </div>
            <div className="col-md-4 mb-3">
                <label className="field_Label" >Lender Mobile Number</label>
                <input
                    type="text"
                    required
                    name="Lender_Registered_Mobile_No" max={10}
                    value={customer.Lender_Registered_Mobile_No}
                    onChange={handleChange}
                    className="form-control form-agreement-field"

                />
            </div>

            <div className="col-md-4 mb-3">
                <label className="field_Label" >Lender Alt Mobile Number</label>
                <input
                    type="text"
                    name="Lender_Mobile_No"
                    required
                    value={customer.Lender_Mobile_No}
                    onChange={handleChange}
                    className="form-control form-agreement-field"

                />
            </div>

            <div className="col-md-4 mb-3">
                <label className="field_Label" >Lender Email ID</label>
                <input
                    type="email"
                    name="Lender_Email_ID"
                    required
                    value={customer.Lender_Email_ID}
                    onChange={handleChange}
                    className="form-control form-agreement-field"

                />
            </div>

            <div className="col-md-12 mb-3">
                <label className="field_Label" >Lender Address</label>
                <input
                    name="Lender_Address"
                    required
                    value={customer.Lender_Address}
                    onChange={handleChange}
                    className="form-control form-agreement-field"
                />
            </div>
            {(customer?.No_of_Lender === "2" || customer?.No_of_Lender === "3") && (
                <>
                    <h6 className="col-md-12"><strong>Second Lender Details: </strong></h6>
                    <div className="col-md-3 mb-3">
                        <label className="field_Label" >Lender Initial </label>
                        <select
                            name="Lender1_PreName"
                            value={customer.Lender1_PreName}
                            onChange={handleChange}
                            className="form-control form-agreement-field"
                            required
                        >
                            <option value="Mr.">Mr.</option>
                            <option value="Miss.">Miss.</option>
                            <option value="Mrs.">Mrs.</option>
                            <option value="Dr.">Dr.</option>
                            {/* Add more options if needed */}
                        </select>
                    </div>

                    <div className="col-md-9 mb-3">
                        <label className="field_Label" >Lender Name</label>
                        <input
                            type="text"
                            name="Lender1_Name"
                            required
                            value={customer.Lender1_Name}
                            onChange={handleChange}
                            className="form-control form-agreement-field"
                        />
                    </div></>
            )}
            {customer?.No_of_Lender === "3" && (
                <>
                    <h6 className="col-md-12"><strong>Third Lender Details: </strong></h6>
                    <div className="col-md-3 mb-3">
                        <label className="field_Label" >Lender Initial </label>
                        <select
                            name="Lender2_PreName"
                            value={customer.Lender2_PreName}
                            onChange={handleChange}
                            className="form-control form-agreement-field"
                            required
                        >
                            <option value="Mr.">Mr.</option>
                            <option value="Miss.">Miss.</option>
                            <option value="Mrs.">Mrs.</option>
                            <option value="Dr.">Dr.</option>
                            {/* Add more options if needed */}
                        </select>
                    </div>

                    <div className="col-md-9 mb-3">
                        <label className="field_Label" >Lender Name</label>
                        <input
                            type="text"
                            name="Lender2_Name"
                            required
                            value={customer.Lender2_Name}
                            onChange={handleChange}
                            className="form-control form-agreement-field"
                        />
                    </div></>
            )}




            {/* <h6 className="col-md-12"><strong>Borrower Details: </strong></h6>
                                <div className="col-md-3 mb-3">
                                    <label className="field_Label" >Borrower Initial </label>
                                    <select
                                        name="Borrower_PreName"
                                        required
                                        value={customer.Borrower_PreName}
                                        onChange={handleChange}
                                        className="form-control form-agreement-field"
                                    >
                                        <option value="Mr.">Mr.</option>
                                        <option value="Miss.">Miss.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Dr.">Dr.</option>

                                    </select>
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label className="field_Label" >Borrower Name</label>
                                    <input
                                        type="text"
                                        name="Borrower_Name"
                                        required
                                        value={customer.Borrower_Name}
                                        onChange={handleChange}
                                        className="form-control form-agreement-field"
                                    />
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label className="field_Label" >Borrower Email ID</label>
                                    <input
                                        type="email"
                                        name="Borrower_Email_ID"
                                        required
                                        value={customer.Borrower_Email_ID}
                                        onChange={handleChange}
                                        className="form-control form-agreement-field"

                                    />
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label className="field_Label" >Borrower Address</label>
                                    <input
                                        name="Borrower_Address"
                                        required
                                        value={customer.Borrower_Address}
                                        onChange={handleChange}
                                        className="form-control form-agreement-field"
                                    />
                                </div> */}


            {customer.bankCount > 0 || customer.onlineCount > 0 ? (
                <>
                    <h6 className="col-md-12"><strong>Lender Bank Details: </strong></h6>
                    <div className="col-md-6 mb-3">
                        <label className="field_Label" >Pay In Bank Count </label>
                        <select name="Pay_In_Bank_Count" value={customer.Pay_In_Bank_Count} onChange={handleChange} required
                            className="form-control form-agreement-field">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label className="field_Label" >Pay Out Bank Count </label>
                        <select name="Pay_Out_Bank_Count" value={customer.Pay_Out_Bank_Count} onChange={handleChange} required
                            className="form-control form-agreement-field">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                    {/* PayIn Bank Account Holder Name */}
                    {PayInBank.map((bank, index) => (
                        <div className="col-md-12" key={index}>
                            <h5 className="col-md-12" style={{ borderBottom: "1px solid #37546c", fontSize: "15px" }}>Pay In Bank Details {index + 1}</h5>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="field_Label">Account Holder Name</label>
                                    <input
                                        type="text"
                                        name="PayIn_Bank_Account_Holder_Name"
                                        value={bank.PayIn_Bank_Account_Holder_Name}
                                        onChange={(e) => handlePayInBankDetailChange(index, e)}
                                        className="form-control form-agreement-field"
                                        required
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label">Bank Name</label>
                                    <input
                                        type="text"
                                        name="PayIn_Bank_Name"
                                        value={bank.PayIn_Bank_Name}
                                        onChange={(e) => handlePayInBankDetailChange(index, e)}
                                        className="form-control form-agreement-field"
                                        required
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label">Account Number</label>
                                    <input
                                        type="text"
                                        name="PayIn_Account_Number"
                                        value={bank.PayIn_Account_Number}
                                        onChange={(e) => handlePayInBankDetailChange(index, e)}
                                        className="form-control form-agreement-field"
                                        required
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label">IFSC Code</label>
                                    <input
                                        type="text"
                                        name="PayIn_IFSC_Code"
                                        value={bank.PayIn_IFSC_Code}
                                        onChange={(e) => handlePayInBankDetailChange(index, e)}
                                        className="form-control form-agreement-field"
                                        required
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label">Branch</label>
                                    <input
                                        type="text"
                                        name="PayIn_Branch"
                                        value={bank.PayIn_Branch}
                                        onChange={(e) => handlePayInBankDetailChange(index, e)}
                                        className="form-control form-agreement-field"
                                        required
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label">Account Holder Type</label>
                                    <input
                                        type="text"
                                        name="PayIn_Account_Holder_Type"
                                        value={bank.PayIn_Account_Holder_Type}
                                        onChange={(e) => handlePayInBankDetailChange(index, e)}
                                        className="form-control form-agreement-field"
                                        required
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label">Account Type</label>
                                    <input
                                        type="text"
                                        name="PayIn_Account_Type"
                                        value={bank.PayIn_Account_Type}
                                        onChange={(e) => handlePayInBankDetailChange(index, e)}
                                        className="form-control form-agreement-field"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* PayOut Bank Account Holder Name */}
                    {PayOutBank.map((bank, index) => (
                        <div className="col-md-12" key={index}>
                            <h5 className="col-md-12" style={{ borderBottom: "1px solid #37546c", fontSize: "15px" }}>Pay Out Bank Details {index + 1}</h5>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label className="field_Label">Account Holder Name</label>
                                    <input
                                        type="text"
                                        name="PayOut_Bank_Account_Holder_Name"
                                        value={bank.PayOut_Bank_Account_Holder_Name}
                                        onChange={(e) => handlePayOutBankDetailChange(index, e)}
                                        className="form-control form-agreement-field"
                                        required
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label">Bank Name</label>
                                    <input
                                        type="text"
                                        name="PayOut_Bank_Name"
                                        value={bank.PayOut_Bank_Name}
                                        onChange={(e) => handlePayOutBankDetailChange(index, e)}
                                        className="form-control form-agreement-field"
                                        required
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label">Account Number</label>
                                    <input
                                        type="text"
                                        name="PayOut_Account_Number"
                                        value={bank.PayOut_Account_Number}
                                        onChange={(e) => handlePayOutBankDetailChange(index, e)}
                                        className="form-control form-agreement-field"
                                        required
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label">IFSC Code</label>
                                    <input
                                        type="text"
                                        name="PayOut_IFSC_Code"
                                        value={bank.PayOut_IFSC_Code}
                                        onChange={(e) => handlePayOutBankDetailChange(index, e)}
                                        className="form-control form-agreement-field"
                                        required
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label">Branch</label>
                                    <input
                                        type="text"
                                        name="PayOut_Branch"
                                        value={bank.PayOut_Branch}
                                        onChange={(e) => handlePayOutBankDetailChange(index, e)}
                                        className="form-control form-agreement-field"
                                        required
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label">Account Holder Type</label>
                                    <input
                                        type="text"
                                        name="PayOut_Account_Holder_Type"
                                        value={bank.PayOut_Account_Holder_Type}
                                        onChange={(e) => handlePayOutBankDetailChange(index, e)}
                                        className="form-control form-agreement-field"
                                        required
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label">Account Type</label>
                                    <input
                                        type="text"
                                        name="PayOut_Account_Type"
                                        value={bank.PayOut_Account_Type}
                                        onChange={(e) => handlePayOutBankDetailChange(index, e)}
                                        className="form-control form-agreement-field"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    ))}


                </>
            ) : (
                <>
                </>
            )}
            {/* <h6 className="col-md-12"><strong>Payout Details: </strong></h6>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label" >Account Holder Initial </label>
                                    <select
                                        name="Payout_Bank_Account_Holder_PreName"
                                        value={customer.Payout_Bank_Account_Holder_PreName}
                                        onChange={handleChange}
                                        className="form-control form-agreement-field"
                                        required
                                    >
                                        <option value="Mr.">Mr.</option>
                                        <option value="Miss.">Miss.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Dr.">Dr.</option>
                                    </select>
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label" >Account Holder Name</label>
                                    <input
                                        type="text"
                                        name="Payout_Bank_Account_Holder_Name"
                                        required
                                        value={customer.Payout_Bank_Account_Holder_Name}
                                        onChange={handleChange}
                                        className="form-control form-agreement-field"
                                    // placeholder="Enter account holder name"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label" >Bank Name</label>
                                    <input
                                        type="text"
                                        name="Payout_Bank_Name"
                                        required
                                        value={customer.Payout_Bank_Name}
                                        onChange={handleChange}
                                        className="form-control form-agreement-field"
                                    //placeholder="Enter bank name"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label" >Account Number</label>
                                    <input
                                        type="text"
                                        name="Payout_Account_Number"
                                        required
                                        value={customer.Payout_Account_Number}
                                        onChange={handleChange}
                                        className="form-control form-agreement-field"
                                    // placeholder="Enter account number"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label" >IFSC Code</label>
                                    <input
                                        type="text"
                                        name="Payout_IFSC_Code"
                                        value={customer.Payout_IFSC_Code}
                                        onChange={handleChange}
                                        required
                                        className="form-control form-agreement-field"
                                    //placeholder="Enter IFSC code"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label" >Branch</label>
                                    <input
                                        type="text"
                                        name="Payout_Branch"
                                        value={customer.Payout_Branch}
                                        onChange={handleChange}
                                        required
                                        className="form-control form-agreement-field"
                                    //placeholder="Enter branch name"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label" >Account Holder Type</label>
                                    <input
                                        type="text"
                                        name="Payout_Account_Holder_Type"
                                        value={customer.Payout_Account_Holder_Type}
                                        onChange={handleChange}
                                        required
                                        className="form-control form-agreement-field"
                                    //placeholder="Enter account holder type"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label className="field_Label" >Account Type</label>
                                    <input
                                        type="text"
                                        name="Payout_Account_Type"
                                        required
                                        value={customer.Payout_Account_Type}
                                        onChange={handleChange}
                                        className="form-control form-agreement-field"
                                    //placeholder="Enter account type"
                                    />
                                </div> */}

            <h6 className="col-md-12"><strong>Nominee Details: </strong></h6>

            {/* Nominee Details */}
            {/* Nominee 1 Initial  */}
            <div className="col-md-3 mb-3">
                <label className="field_Label" >Nominee Initial </label>
                <select
                    name="Nominee1_PreName"
                    required
                    value={customer.Nominee1_PreName}
                    onChange={handleChange}
                    className="form-control form-agreement-field"
                >
                    <option value="Mr.">Mr.</option>
                    <option value="Miss.">Miss.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Dr.">Dr.</option>
                </select>
            </div>

            <div className="col-md-3 mb-3">
                <label className="field_Label" >Nominee Name</label>
                <input
                    type="text"
                    name="Nominee1_Name"
                    value={customer.Nominee1_Name}
                    onChange={handleChange}
                    required
                    className="form-control form-agreement-field"
                />
            </div>


            <div className="col-md-3 mb-3">
                <label className="field_Label" >Nominee Address</label>
                <input
                    type="text"
                    name="Nominee1_Address"
                    required
                    value={customer.Nominee1_Address}
                    onChange={handleChange}
                    className="form-control form-agreement-field"
                />
            </div>

            <div className="col-md-3 mb-3">
                <label className="field_Label" >Nominee Gender</label>
                <select
                    name="Nomination_Gender"
                    value={customer.Nomination_Gender}
                    onChange={handleChange}
                    required
                    className="form-control form-agreement-field"
                >
                    <option value="">Select Gender</option>
                    <option value="his">Male</option>
                    <option value="her">Female</option>

                </select>
            </div>

            <div className="col-md-3 mb-3">
                <label className="field_Label" >Nominee Loan Amount</label>
                <input
                    type="text"
                    name="Nominee_Loan_Amount"
                    required
                    value={customer.Nominee_Loan_Amount}
                    onChange={(e) => {
                        const input = e.target.value;
                        const decimalInput = input.replace(/[^0-9.]/g, "");
                        const amount = decimalInput.replace(
                            /^(\d*\.\d*)\..*$/,
                            "$1"
                        );
                        setCustomer({
                            ...customer,
                            Nominee_Loan_Amount: amount,
                        });

                    }}
                    className="form-control form-agreement-field"
                />
            </div>

            <div className="col-md-3 mb-3">
                <label className="field_Label" >Nomination Date</label>
                <input
                    type="date"
                    name="Nomination_Date"
                    required
                    value={customer.Nomination_Date}
                    onChange={handleChange}
                    className="form-control form-agreement-field"
                />
            </div>

            <div className="col-md-3 mb-3">
                <label className="field_Label" >Nominee DOB</label>
                <input
                    type="date"
                    name="Nominee_DOB"
                    required
                    value={customer.Nominee_DOB}
                    onChange={handleChange}
                    className="form-control form-agreement-field"
                />
            </div>

            <div className="col-md-3 mb-3">
                <label className="field_Label" >Nominee Relation</label>
                <input
                    type="text"
                    name="Nominee_Relation"
                    required
                    value={customer.Nominee_Relation}
                    onChange={handleChange}
                    className="form-control form-agreement-field"
                />
            </div>


            <h6 className="col-md-12"><strong>Witness Details: </strong></h6>

            <div className="col-md-3 mb-3">
                <label className="field_Label" >Witness Initial </label>
                <select
                    name="Declaration_Witness_PreName"
                    value={customer.Declaration_Witness_PreName}
                    onChange={handleChange}
                    required
                    className="form-control form-agreement-field"
                >
                    <option value="Mr.">Mr.</option>
                    <option value="Miss.">Miss.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Dr.">Dr.</option>
                </select>
            </div>

            <div className="col-md-3 mb-3">
                <label className="field_Label" >Witness Name</label>
                <input
                    type="text"
                    name="Declaration_Witness_Name"
                    required
                    value={customer.Declaration_Witness_Name}
                    onChange={handleChange}
                    className="form-control form-agreement-field"
                />
            </div>

            <div className="col-md-6 mb-3">
                <label className="field_Label" >Witness Address</label>
                <input
                    type="text"
                    name="Declaration_Witness_Address"
                    required
                    value={customer.Declaration_Witness_Address}
                    onChange={handleChange}
                    className="form-control form-agreement-field"
                />
            </div>
            <h6 className="col-md-12"><strong>Documents: </strong></h6>
            <div className={Id ? "col-md-3" : "col-md-4"}>
                <div className="mb-3">
                    <label className="form-label field_Label" htmlFor="Bank_Details">
                        Bank Details
                    </label>
                    <div className="custom-file">
                        <input
                            type="file"
                            className="custom-file-input"
                            id="Bank_Details"
                            name="Bank_Details"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => {
                                const fileName =
                                    e.target.files[0]?.name || "Choose file";
                                setBank_DetailsFileName(fileName);

                                setCustomer({
                                    ...customer,
                                    Bank_Details: e.target.files[0],
                                });
                            }}
                        />
                        <label
                            className="custom-file-label"
                            htmlFor="Bank_Details"
                            style={{ overflow: "hidden" }}
                        >
                            {Bank_DetailsFileName}
                        </label>
                    </div>
                </div>
            </div>
            <div className={Id ? "col-md-1" : "d-none"} style={{ marginTop: "33px" }}>
                <div className="mb-3">
                    {customer.Bank_Details ? (
                        <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/FreshAgreement/BankDetail/` + customer.Bank_Details} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                                <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/FreshAgreement/BankDetail/` + customer.Bank_Details} className="cursor-pointer">
                                <DownloadIcon fontSize="medium" />
                            </a></>
                    ) : (
                        <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                    )}
                </div>
            </div>
            <div className={Id ? "col-md-3" : "col-md-4"}>
                <div className="mb-3">
                    <label className="form-label field_Label" htmlFor="Payment_Proof">
                        Payment Proof
                    </label>
                    <div className="custom-file">
                        <input
                            type="file"
                            className="custom-file-input"
                            id="Payment_Proof"
                            name="Payment_Proof"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => {
                                const fileName =
                                    e.target.files[0]?.name || "Choose file";
                                setPayment_ProofFileName(fileName);

                                setCustomer({
                                    ...customer,
                                    Payment_Proof: e.target.files[0],
                                });
                            }}
                        />
                        <label
                            className="custom-file-label"
                            htmlFor="Payment_Proof"
                            style={{ overflow: "hidden" }}
                        >
                            {Payment_ProofFileName}
                        </label>
                    </div>
                </div>
            </div>
            <div className={Id ? "col-md-1" : "d-none"} style={{ marginTop: "33px" }}>
                <div className="mb-3">
                    {customer?.Payment_Proof ? (
                        <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/FreshAgreement/PaymentProof/` + customer?.Payment_Proof} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                                <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/FreshAgreement/PaymentProof/` + customer?.Payment_Proof} className="cursor-pointer">
                                <DownloadIcon fontSize="medium" />
                            </a></>
                    ) : (
                        <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                    )}
                </div>
            </div>
            <div className={Id ? "col-md-3" : "col-md-4"}>
                <div className="mb-3">
                    <label className="form-label field_Label" htmlFor="Aadhar_Card">
                        Aadhar Card
                    </label>
                    <div className="custom-file">
                        <input
                            type="file"
                            className="custom-file-input"
                            id="Aadhar_Card"
                            name="Aadhar_Card"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => {
                                const fileName =
                                    e.target.files[0]?.name || "Choose file";
                                setAadhar_CardFileName(fileName);

                                setCustomer({
                                    ...customer,
                                    Aadhar_Card: e.target.files[0],
                                });
                            }}
                        />
                        <label
                            className="custom-file-label"
                            htmlFor="Aadhar_Card"
                            style={{ overflow: "hidden" }}
                        >
                            {Aadhar_CardFileName}
                        </label>
                    </div>
                </div>
            </div>
            <div className={Id ? "col-md-1" : "d-none"} style={{ marginTop: "33px" }}>
                <div className="mb-3">
                    {customer?.Aadhar_Card ? (
                        <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/FreshAgreement/AadharCard/` + customer?.Aadhar_Card} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                                <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/FreshAgreement/AadharCard/` + customer?.Aadhar_Card} className="cursor-pointer">
                                <DownloadIcon fontSize="medium" />
                            </a></>
                    ) : (
                        <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                    )}
                </div>
            </div>
            <div className={Id ? "col-md-3" : "col-md-4"}>
                <div className="mb-3">
                    <label className="form-label field_Label" htmlFor="Pan_Card">
                        Pan Card
                    </label>
                    <div className="custom-file">
                        <input
                            type="file"
                            className="custom-file-input"
                            id="Pan_Card"
                            name="Pan_Card"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => {
                                const fileName =
                                    e.target.files[0]?.name || "Choose file";
                                setPan_CardFileName(fileName);

                                setCustomer({
                                    ...customer,
                                    Pan_Card: e.target.files[0],
                                });
                            }}
                        />
                        <label
                            className="custom-file-label"
                            htmlFor="Pan_Card"
                            style={{ overflow: "hidden" }}
                        >
                            {Pan_CardFileName}
                        </label>
                    </div>
                </div>
            </div>
            <div className={Id ? "col-md-1" : "d-none"} style={{ marginTop: "33px" }}>
                <div className="mb-3">
                    {customer?.Pan_Card ? (
                        <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/FreshAgreement/PanCard/` + customer?.Pan_Card} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                                <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/FreshAgreement/PanCard/` + customer?.Pan_Card} className="cursor-pointer">
                                <DownloadIcon fontSize="medium" />
                            </a></>
                    ) : (
                        <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                    )}
                </div>
            </div>
            <div className={Id ? "col-md-3" : "col-md-4"}>
                <div className="mb-3">
                    <label className="form-label field_Label" htmlFor="Attachment1">
                        Attachment1
                    </label>
                    <div className="custom-file">
                        <input
                            type="file"
                            className="custom-file-input"
                            id="Attachment1"
                            name="Attachment1"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => {
                                const fileName =
                                    e.target.files[0]?.name || "Choose file";
                                setAttachment1FileName(fileName);

                                setCustomer({
                                    ...customer,
                                    Attachment1: e.target.files[0],
                                });
                            }}
                        />
                        <label
                            className="custom-file-label"
                            htmlFor="Attachment1"
                            style={{ overflow: "hidden" }}
                        >
                            {Attachment1FileName}
                        </label>
                    </div>
                </div>
            </div>
            <div className={Id ? "col-md-1" : "d-none"} style={{ marginTop: "33px" }}>
                <div className="mb-3">
                    {customer?.Attachment1 ? (
                        <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/FreshAgreement/Attachment1/` + customer?.Attachment1} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                                <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/FreshAgreement/Attachment1/` + customer?.Attachment1} className="cursor-pointer">
                                <DownloadIcon fontSize="medium" />
                            </a></>
                    ) : (
                        <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                    )}
                </div>
            </div>
            <div className={Id ? "col-md-3" : "col-md-4"}>
                <div className="mb-3">
                    <label className="form-label field_Label" htmlFor="Attachment2">
                        Attachment2
                    </label>
                    <div className="custom-file">
                        <input
                            type="file"
                            className="custom-file-input"
                            id="Attachment2"
                            name="Attachment2"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => {
                                const fileName =
                                    e.target.files[0]?.name || "Choose file";
                                setAttachment2FileName(fileName);

                                setCustomer({
                                    ...customer,
                                    Attachment2: e.target.files[0],
                                });
                            }}
                        />
                        <label
                            className="custom-file-label"
                            htmlFor="Attachment2"
                            style={{ overflow: "hidden" }}
                        >
                            {Attachment2FileName}
                        </label>
                    </div>
                </div>
            </div>
            <div className={Id ? "col-md-1" : "d-none"} style={{ marginTop: "33px" }}>
                <div className="mb-3">
                    {customer?.Attachment2 ? (
                        <>
                            <a style={{ color: "blue" }} href={`${apiUrl}/FreshAgreement/Attachment2/` + customer?.Attachment2} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                                <Visibility fontSize="medium" />
                            </a> /
                            <a style={{ color: "blue" }} href={`${apiUrl}/FreshAgreement/Attachment2/` + customer?.Attachment2} className="cursor-pointer">
                                <DownloadIcon fontSize="medium" />
                            </a></>
                    ) : (
                        <><VisibilityOffIcon /> / <FileDownloadOffIcon /></>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AgreementFormNew;