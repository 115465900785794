import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiUrl } from "../../componets/apiConfig";
import { useParams } from "react-router-dom";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { formatDate, formatInputDate, calculateEndDate } from "../../mui/dateFormate";
import Swal from "sweetalert2";
import AgreementPreview from "./AgreementPreview"
import { useUser } from "../../componets/UserContext";
import html2pdf from "html2pdf.js";
import AgreementFormNew from "./AgreementFormNew";
import "../../CSS/agreement.css";



const CustomerAgreement = () => {
    const [userData, setuserData] = useState({});
    const navigate = useNavigate();
    const { setData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [errors, setErrors] = useState({});
    const [IsPreview, setIsPreview] = useState(false);
    const [Bank_DetailsFileName, setBank_DetailsFileName] = useState("Choose file");
    const [Payment_ProofFileName, setPayment_ProofFileName] = useState("Choose file");
    const [Aadhar_CardFileName, setAadhar_CardFileName] = useState("Choose file");
    const [Pan_CardFileName, setPan_CardFileName] = useState("Choose file");
    const [Attachment1FileName, setAttachment1FileName] = useState("Choose file");
    const [Attachment2FileName, setAttachment2FileName] = useState("Choose file");
    const { Id } = useParams();
    const [transactionDetails, setTransactionDetails] = useState([]);
    const [PayInBank, setPayInBank] = useState([{
        PayIn_Bank_Account_Holder_Name: '',
        PayIn_Bank_Name: '',
        PayIn_Account_Number: '',
        PayIn_IFSC_Code: '',
        PayIn_Branch: '',
        PayIn_Account_Holder_Type: '',
        PayIn_Account_Type: ''
    }]);
    const [PayOutBank, setPayOutBank] = useState([{
        PayOut_Bank_Account_Holder_Name: '',
        PayOut_Bank_Name: '',
        PayOut_Account_Number: '',
        PayOut_IFSC_Code: '',
        PayOut_Branch: '',
        PayOut_Account_Holder_Type: '',
        PayOut_Account_Type: ''
    }]);
    useEffect(() => {
        showLoader();        
    
        axios.get(`${apiUrl}/Customerdashboard`, { withCredentials: true })
            .then((res) => {
                if (res.data.Status === "Success") {
                    const userData = {
                        userId: res.data.UserId,
                        role: res.data.Role,
                        Name: res.data.Name,
                        Email: res.data.Email,
                        Org_Id: res.data.Org_Id,
                        Mobile: res.data.Mobile,
                    };
                    hideLoader();
                    setuserData(userData);
                    setData(userData);
                   // console.log(userData);
                } else {
                    console.error("Customer Auth Error:" + res);
                    navigate("/CustomerLogin");
                }
            });

    }, []);
    

    

    const [customer, setCustomer] = useState({
        AgDate: new Date(),
        Location: "",
        No_of_Lender: "1",
        Lender_PreName: "Mr.",
        Lender_Name: "",
        Lender1_PreName: "Mr.",
        Lender1_Name: "",
        Lender2_PreName: "Mr.",
        Lender2_Name: "",
        Lender_Address: "",
        Lender_Mobile_No: "",
        Lender_Registered_Mobile_No: "",
        Lender_Email_ID: "",
        Borrower_PreName: "Mr.",
        Borrower_Name: "",
        Borrower_Address: "",
        Borrower_Email_ID: "",
        Borrower_Mobile: "",
        Borrower_Bank: "",
        Loan_Amount_txt: "",
        Loan_Amount_num: "",
        Loan_Start_Date: "",
        Loan_End_Date: "",

        Transaction_Type: "1",

        Mode_of_Payment1: "Cash",
        Transaction_ID1: "",
        Bank1: "",
        Cash_Received_By1: "",
        Cash_Handovered_To1: "",

        Mode_of_Payment2: "",
        Transaction_ID2: "",
        Bank2: "",
        Cash_Received_By2: "",
        Cash_Handovered_To2: "",

        Mode_of_Payment3: "",
        Transaction_ID3: "",
        Bank3: "",
        Cash_Received_By3: "",
        Cash_Handovered_To3: "",

        Mode_of_Payment4: "",
        Transaction_ID4: "",
        Bank4: "",
        Cash_Received_By4: "",
        Cash_Handovered_To4: "",

        Mode_of_Payment5: "",
        Transaction_ID5: "",
        Bank5: "",
        Cash_Received_By5: "",
        Cash_Handovered_To5: "",

        Loan_Disbursal_Date: "",
        Total_Loan_Amount: "",
        Received_Date: "",
        Tenure: "Six Months",
        KYC_Date: "",
        KYC_Time: "",
        LockIn_Tenure: "Three Months",
        LockIn_Period_From_Date: "",
        LockIn_Period_To_Date: "",
        Penalty_Percent: "6",
        Penalty_Amount: "0",

        PayIn_Bank_Account_Holder_PreName: "Mr.",
        PayIn_Bank_Account_Holder_Name: "",
        PayIn_Bank_Name: "",
        PayIn_Account_Number: "",
        PayIn_IFSC_Code: "",
        PayIn_Branch: "",
        PayIn_Account_Holder_Type: "",
        PayIn_Account_Type: "",

        Payout_Bank_Account_Holder_PreName: "Mr.",
        Payout_Bank_Account_Holder_Name: "",
        Payout_Bank_Name: "",
        Payout_Account_Number: "",
        Payout_IFSC_Code: "",
        Payout_Branch: "",
        Payout_Account_Holder_Type: "",
        Payout_Account_Type: "",

        Nominee1_PreName: "Mr.",
        Nominee1_Name: "",
        Nominee1_Address: "",
        Nomination_Gender: "his",
        Nominee_Loan_Amount: "",
        Nomination_Date: "",
        Nominee_DOB: "",
        Nominee_Relation: "",

        Declaration_Witness_PreName: "Mr.",
        Declaration_Witness_Name: "",
        Declaration_Witness_Address: "",
        Bank_Details: "",
        Payment_Proof: "",
        Aadhar_Card: "",
        Pan_Card: "",
        Attachment1: "",
        Attachment2: "",

        cashCount: 0,
        onlineCount: 0,
        bankCount: 0,
        Pay_In_Bank_Count:1,
        Pay_Out_Bank_Count:1
    });


    const generateTaxInvoiceAndSavePDF = async (invoice) => {
        const element = document.getElementById("printablediv");
        const opt = {
            margin: 10,
            filename: "TaxInvoice.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };

        try {
            const pdfBlob = await html2pdf().from(element).set(opt).outputPdf("blob");

            const whatsapp = {
                Customer: invoice?.Customer_Name,
                Certificate: invoice?.Certificate_Name,
                number: invoice?.Mobile,
                Email: invoice?.Email,
            };
            //console.log(whatsapp);
            showLoader();

            const formData = new FormData();
            formData.append("pdf", pdfBlob, "TaxInvoice.pdf");

            const response = await axios.post(`/saveAgreementDoc`, formData);
            hideLoader();


        } catch (error) {
            // Handle any errors
            console.error("Error saving PDF:", error);
            hideLoader();
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomer((prevCustomer) => ({
            ...prevCustomer,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        const formdata = new FormData();

        const updatedCustomer = {
            ...customer,
            User_Role: userData?.role,
            Added_By_Employee_Id: userData?.Employee_Id || "",
            Added_By_Id: userData.userId,
            Added_By: `${userData?.firstName} ${userData?.lastName}`,
            Org_Id: userData?.Org_Id,
            UserName: userData?.UserName,
            transactionDetails: transactionDetails,
            PayInBank: PayInBank,
            PayOutBank: PayOutBank
        };

        // Append all customer data to formdata

       // Manually append each property from updatedCustomer
       formdata.append('AgDate', updatedCustomer.AgDate || "");
       formdata.append('Location', updatedCustomer.Location || "");
       formdata.append('No_of_Lender', updatedCustomer.No_of_Lender || "");
       formdata.append('Lender_PreName', updatedCustomer.Lender_PreName || "");
       formdata.append('Lender_Name', updatedCustomer.Lender_Name || "");
       formdata.append('Lender1_PreName', updatedCustomer.Lender1_PreName || "");
       formdata.append('Lender1_Name', updatedCustomer.Lender1_Name || "");
       formdata.append('Lender2_PreName', updatedCustomer.Lender2_PreName || "");
       formdata.append('Lender2_Name', updatedCustomer.Lender2_Name || "");
       formdata.append('Lender_Address', updatedCustomer.Lender_Address || "");
       formdata.append('Lender_Mobile_No', updatedCustomer.Lender_Mobile_No || "");
       formdata.append('Lender_Registered_Mobile_No', updatedCustomer.Lender_Registered_Mobile_No || "");
       formdata.append('Lender_Email_ID', updatedCustomer.Lender_Email_ID || "");
       formdata.append('Borrower_PreName', updatedCustomer.Borrower_PreName || "");
       formdata.append('Borrower_Name', updatedCustomer.Borrower_Name || "");
       formdata.append('Borrower_Address', updatedCustomer.Borrower_Address || "");
       formdata.append('Borrower_Email_ID', updatedCustomer.Borrower_Email_ID || "");
       formdata.append('Borrower_Mobile', updatedCustomer.Borrower_Mobile || "");
       formdata.append('Borrower_Bank', updatedCustomer.Borrower_Bank || "");
       formdata.append('Loan_Amount_txt', updatedCustomer.Loan_Amount_txt || "");
       formdata.append('Loan_Amount_num', updatedCustomer.Loan_Amount_num || "");
       formdata.append('Loan_Start_Date', formatInputDate(updatedCustomer.Loan_Start_Date) || "");
       formdata.append('Loan_End_Date', formatInputDate(updatedCustomer.Loan_End_Date) || "");
       formdata.append('Transaction_Type', updatedCustomer.Transaction_Type || "");

       formdata.append('Mode_of_Payment1', updatedCustomer.Mode_of_Payment1 || "");
       formdata.append('Transaction_ID1', updatedCustomer.Transaction_ID1 || "");
       formdata.append('Bank1', updatedCustomer.Bank1 || "");
       formdata.append('Cash_Received_By1', updatedCustomer.Cash_Received_By1 || "");
       formdata.append('Cash_Handovered_To1', updatedCustomer.Cash_Handovered_To1 || "");

       formdata.append('Mode_of_Payment2', updatedCustomer.Mode_of_Payment2 || "");
       formdata.append('Transaction_ID2', updatedCustomer.Transaction_ID2 || "");
       formdata.append('Bank2', updatedCustomer.Bank2 || "");
       formdata.append('Cash_Received_By2', updatedCustomer.Cash_Received_By2 || "");
       formdata.append('Cash_Handovered_To2', updatedCustomer.Cash_Handovered_To2 || "");

       formdata.append('Mode_of_Payment3', updatedCustomer.Mode_of_Payment3 || "");
       formdata.append('Transaction_ID3', updatedCustomer.Transaction_ID3 || "");
       formdata.append('Bank3', updatedCustomer.Bank3 || "");
       formdata.append('Cash_Received_By3', updatedCustomer.Cash_Received_By3 || "");
       formdata.append('Cash_Handovered_To3', updatedCustomer.Cash_Handovered_To3 || "");

       formdata.append('Mode_of_Payment4', updatedCustomer.Mode_of_Payment4 || "");
       formdata.append('Transaction_ID4', updatedCustomer.Transaction_ID4 || "");
       formdata.append('Bank4', updatedCustomer.Bank4 || "");
       formdata.append('Cash_Received_By4', updatedCustomer.Cash_Received_By4 || "");
       formdata.append('Cash_Handovered_To4', updatedCustomer.Cash_Handovered_To4 || "");

       formdata.append('Mode_of_Payment5', updatedCustomer.Mode_of_Payment5 || "");
       formdata.append('Transaction_ID5', updatedCustomer.Transaction_ID5 || "");
       formdata.append('Bank5', updatedCustomer.Bank5 || "");
       formdata.append('Cash_Received_By5', updatedCustomer.Cash_Received_By5 || "");
       formdata.append('Cash_Handovered_To5', updatedCustomer.Cash_Handovered_To5 || "");

       formdata.append('Loan_Disbursal_Date', formatInputDate(updatedCustomer.Loan_Disbursal_Date) || "");
       formdata.append('Total_Loan_Amount', updatedCustomer.Total_Loan_Amount || "");
       formdata.append('Received_Date', formatInputDate(updatedCustomer.Received_Date) || "");
       formdata.append('Tenure', updatedCustomer.Tenure || "");
       formdata.append('KYC_Date', formatInputDate(updatedCustomer.KYC_Date) || "");
       formdata.append('KYC_Time', updatedCustomer.KYC_Time || "");
       formdata.append('LockIn_Tenure', updatedCustomer.LockIn_Tenure || "");
       formdata.append('LockIn_Period_From_Date', formatInputDate(updatedCustomer.LockIn_Period_From_Date) || "");
       formdata.append('LockIn_Period_To_Date', formatInputDate(updatedCustomer.LockIn_Period_To_Date) || "");
       formdata.append('Penalty_Percent', updatedCustomer.Penalty_Percent || "0");
       formdata.append('Penalty_Amount', updatedCustomer.Penalty_Amount || "0");
       formdata.append('PayIn_Bank_Account_Holder_PreName', updatedCustomer.PayIn_Bank_Account_Holder_PreName || "");
       formdata.append('PayIn_Bank_Account_Holder_Name', updatedCustomer.PayIn_Bank_Account_Holder_Name || "");
       formdata.append('PayIn_Bank_Name', updatedCustomer.PayIn_Bank_Name || "");
       formdata.append('PayIn_Account_Number', updatedCustomer.PayIn_Account_Number || "");
       formdata.append('PayIn_IFSC_Code', updatedCustomer.PayIn_IFSC_Code || "");
       formdata.append('PayIn_Branch', updatedCustomer.PayIn_Branch || "");
       formdata.append('PayIn_Account_Holder_Type', updatedCustomer.PayIn_Account_Holder_Type || "");
       formdata.append('PayIn_Account_Type', updatedCustomer.PayIn_Account_Type || "");
       formdata.append('Payout_Bank_Account_Holder_PreName', updatedCustomer.Payout_Bank_Account_Holder_PreName || "");
       formdata.append('Payout_Bank_Account_Holder_Name', updatedCustomer.Payout_Bank_Account_Holder_Name || "");
       formdata.append('Payout_Bank_Name', updatedCustomer.Payout_Bank_Name || "");
       formdata.append('Payout_Account_Number', updatedCustomer.Payout_Account_Number || "");
       formdata.append('Payout_IFSC_Code', updatedCustomer.Payout_IFSC_Code || "");
       formdata.append('Payout_Branch', updatedCustomer.Payout_Branch || "");
       formdata.append('Payout_Account_Holder_Type', updatedCustomer.Payout_Account_Holder_Type || "");
       formdata.append('Payout_Account_Type', updatedCustomer.Payout_Account_Type || "");
       formdata.append('Nominee1_PreName', updatedCustomer.Nominee1_PreName || "");
       formdata.append('Nominee1_Name', updatedCustomer.Nominee1_Name || "");
       formdata.append('Nominee1_Address', updatedCustomer.Nominee1_Address || "");
       formdata.append('Nomination_Gender', updatedCustomer.Nomination_Gender || "");
       formdata.append('Nominee_Loan_Amount', updatedCustomer.Nominee_Loan_Amount || "");
       formdata.append('Nomination_Date', formatInputDate(updatedCustomer.Nomination_Date) || "");
       formdata.append('Nominee_DOB', formatInputDate(updatedCustomer.Nominee_DOB) || "");
       formdata.append('Nominee_Relation', updatedCustomer.Nominee_Relation || "");
       formdata.append('Declaration_Witness_PreName', updatedCustomer.Declaration_Witness_PreName || "");
       formdata.append('Declaration_Witness_Name', updatedCustomer.Declaration_Witness_Name || "");
       formdata.append('Declaration_Witness_Address', updatedCustomer.Declaration_Witness_Address || "");
       formdata.append('Bank_Details', updatedCustomer.Bank_Details || "");
       formdata.append('Payment_Proof', updatedCustomer.Payment_Proof || "");
       formdata.append('Aadhar_Card', updatedCustomer.Aadhar_Card || "");
       formdata.append('Pan_Card', updatedCustomer.Pan_Card || "");
       formdata.append('Attachment1', updatedCustomer.Attachment1 || "");
       formdata.append('Attachment2', updatedCustomer.Attachment2 || "");

       // Append additional fields
       formdata.append('User_Role', updatedCustomer.User_Role || "");
       formdata.append('Added_By_Employee_Id', updatedCustomer.Added_By_Employee_Id || "");
       formdata.append('Added_By_Id', updatedCustomer.Added_By_Id || "");
       formdata.append('Added_By', updatedCustomer.Added_By || "");
       formdata.append('Org_Id', updatedCustomer.Org_Id || "");
       formdata.append('UserName', updatedCustomer.UserName || "0");
       formdata.append('cashCount', updatedCustomer.cashCount || "0");
       formdata.append('onlineCount', updatedCustomer.onlineCount || "0");
       formdata.append('bankCount', updatedCustomer.bankCount || "0");
       formdata.append('Pay_In_Bank_Count', updatedCustomer.Pay_In_Bank_Count || "0");
       formdata.append('Pay_Out_Bank_Count', updatedCustomer.Pay_Out_Bank_Count || "0");
       formdata.append('transactionDetails', JSON.stringify(updatedCustomer.transactionDetails || []));
       formdata.append('PayInBank', JSON.stringify(updatedCustomer.PayInBank || []));
       formdata.append('PayOutBank', JSON.stringify(updatedCustomer.PayOutBank || []));


        // Optional: Log formdata entries to inspect
        for (let pair of formdata.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }

        const validationErrors = {};


        if (Object.keys(validationErrors).length === 0) {
            showLoader(); // Show loader before starting the request
            if (Id == null || Id === undefined) {
                try {
                    const response = await axios.post(`${apiUrl}/addLoanAgreement`, formdata);
                    // Handle successful response here
                    console.log(response.data);
                    Swal.fire({
                        title: "Success",
                        text: "Loan Agreement successfully added!",
                        icon: "success",
                        confirmButtonText: "OK",
                    });
                } catch (err) {
                    // Handle any errors here
                    console.error("Error while adding loan agreement:", err);
                    Swal.fire({
                        title: "Error",
                        text: "Failed to update. Please try again later.",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                } finally {
                    hideLoader();
                }
            }
            else {
                try {
                    const response = await axios.post(`${apiUrl}/updateLoanAgreement/${Id}`, formdata);
                    console.log(response.data);
                    Swal.fire({
                        title: "Success",
                        text: "Loan Agreement successfully added!",
                        icon: "success",
                        confirmButtonText: "OK",
                    });
                } catch (err) {
                    console.error("Error while adding loan agreement:", err);
                    Swal.fire({
                        title: "Error",
                        text: "Failed to update. Please try again later.",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                } finally {
                    hideLoader(); // Hide loader in both success and error cases
                }
            }
        } else {
            // Set validation errors to display them on the form
            setErrors(validationErrors);
        }
    };


    const showSummarry = async (event) => {
        event.preventDefault();
        const validationErrors = {};

        if (Object.keys(validationErrors).length === 0) {

            setIsPreview(true);
        } else {
            setErrors(validationErrors);
        }
    };

    const closed = (status) => {
        if (status) {
            handleSubmit();
        }
        setIsPreview(false);
    };
    
    const convertNumberToWords = (num) => {
        const a = [
            "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten",
            "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"
        ];
        const b = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

        const n = (number) => {
            if (number < 20) return a[number];
            const digit = number % 10;
            return b[Math.floor(number / 10)] + (digit ? " " + a[digit] : "");
        };

        const convertToWords = (num) => {
            if (num === 0) return "Zero";

            let crore = Math.floor(num / 10000000);
            num %= 10000000;
            let lakh = Math.floor(num / 100000);
            num %= 100000;
            let thousand = Math.floor(num / 1000);
            num %= 1000;
            let hundred = Math.floor(num / 100);
            num %= 100;
            let rest = num;

            let result = "";

            if (crore > 0) result += `${n(crore)} Crore `;
            if (lakh > 0) result += `${n(lakh)} Lakh `;
            if (thousand > 0) result += `${n(thousand)} Thousand `;
            if (hundred > 0) result += `${n(hundred)} Hundred `;
            if (rest > 0) result += n(rest);

            return result.trim();
        };

        return convertToWords(parseInt(num, 10));
    };

    useEffect(() => {
        // If Transaction_Type is reduced, truncate the transactionDetails array
        if (customer.Transaction_Type < transactionDetails.length) {
            setTransactionDetails((prevDetails) => prevDetails.slice(0, customer.Transaction_Type));
        }
    }, [customer.Transaction_Type]);
    const handleTransactionChange = (index, field, value) => {
        const updatedDetails = [...transactionDetails];
        updatedDetails[index] = {
            ...updatedDetails[index],
            [field]: value,
        };
        setTransactionDetails(updatedDetails);
    };
    function clearTransactionFields(mode, num) {
        let updatedCustomer = { ...customer };

        // Clear bank details if mode is "Cash"
        if (mode === "Cash") {
            updatedCustomer[`Bank${num}`] = "";
        }
        // Clear Cash_Received_By and Cash_Handovered_To if mode is neither "Cash" nor "Online"
        else if (mode !== "Cash" && mode !== "Online") {
            updatedCustomer[`Cash_Received_By${num}`] = "";
            updatedCustomer[`Cash_Handovered_To${num}`] = "";
        }

        // Always update the Mode_of_Payment
        updatedCustomer[`Mode_of_Payment${num}`] = mode;

        // Update the customer state
        setCustomer(updatedCustomer);
    }
    
    return (
        <div className="container-fluid">
            <h5 className="page-title mt-4">Fresh Agreement</h5>
            <div className="card">

                <div className="card-body" style={{ paddingTop: "5px" }}>
                    <div>

                        <form onSubmit={showSummarry}>
                        <AgreementFormNew customer={customer} setCustomer={setCustomer} Id={Id}
                                transactionDetails={transactionDetails} setTransactionDetails={setTransactionDetails}
                                PayInBank={PayInBank} setPayInBank={setPayInBank} PayOutBank={PayOutBank} setPayOutBank={setPayOutBank} />
                            <div className="row mt-4">
                                <div className="col-12 text-center">
                                    <button className="btn btn-primary mt-2" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </div>

                        </form>

                    </div>
                    {/* <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PrintIcon />}
                        onClick={handleGeneratePDF}
                    >
                        Download PDF
                    </Button> */}
                    {loader}

                    {IsPreview && (
                        <AgreementPreview
                            open={IsPreview}
                            close={(status) => closed(status)}
                            customer={customer}
                            transactionDetails={transactionDetails}
                            PayInBank={PayInBank}
                            PayOutBank={PayOutBank}
                            status={"0"}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default CustomerAgreement;
