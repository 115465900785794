import React, { useState, useEffect } from 'react';


const AgreementContent = ({ customer, formatDate, formatTime, transactionDetails, PayInBank, PayOutBank }) => {
   
    return (
        <>
            {/* start page1 */}
            <div id="page1">
                <h6 className="text-center"><u style={{ fontWeight: "bold" }}>Loan Agreement</u></h6>
                <p className="agreementContent" style={{ padding: "7px" }}>This Agreement is Made & Executed At Kalyan On {formatDate(customer?.Date)} Between  <strong>{customer?.Lender_PreName} {customer?.Lender_Name}</strong> Residing At, {customer?.Lender_Address}. Hereinafter Referred To As <strong>'Lender'</strong> Of The One Part And <strong>Mr. Pravin Mahadev Dhuri</strong> Residing At Flat No. 503/ C Wing, Sarvoday
                    Pooja CHS, 5Th Floor, Barave Road, Near Ayush Hospital,
                    Khadakpada, Kalyan West, Kalyan, Thane, Maharashtra 421301. Hereinafter Referred To As The <strong>'Borrower'</strong>  Of The Other Part.</p>
            </div>
            <div style={{ pageBreakBefore: 'always' }}></div>
            {/* {hr && <div style={{ borderTop: "5px solid black" }}></div>} */}
            {/* end page1 */}
            <div id="page2" style={{ marginTop: "69px", width: "100%" }}>
                <p className="text-center agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>A loan Agreement between {customer?.Lender_PreName} {customer?.Lender_Name} & Mr. Pravin Mahadev Dhuri.</strong><br />
                    {/* ({customer?.Lender_PreName} {customer?.Lender_Name} is the  <strong>Lender</strong> and {customer?.Borrower_PreName} {customer?.Borrower_Name} is the  <strong>Borrower</strong>.) */}
                </p>
                <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>1. Declaration:</strong><br />
                    This is declared and agreed by Lender that whatever loan amount the
                    lender is giving to the borrower is lender’s own money and it is not
                    borrowed money from any where or not that kind of Money Which is
                    Taken From any Bank or Institution or any individual as a loan.
                </p>
                <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>1.  Loan Amount and Duration: </strong> <br />
                    A loan amount of
                    <strong> Rs. {customer?.Loan_Amount_num}/- </strong>
                    <strong>({customer?.Loan_Amount_txt} Rupees Only)</strong> (Principal Amount)
                    this amount has been transferred To the borrower on
                    <strong> {formatDate(customer?.Loan_Disbursal_Date)}</strong> period for which the loan is granted is One Year means from
                    <strong> {formatDate(customer?.Loan_Start_Date)}</strong> To
                    <strong> {formatDate(customer?.Loan_End_Date)}</strong>.
                </p>
                {(customer?.bankCount > 0 || customer?.onlineCount > 0) && (
                    <>
                        <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}><strong>Online Amount Received Details as follows :-</strong></p>
                        <div style={{ padding: "7px" }}>
                            <table border="1" cellPadding="2" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th>LOAN AMOUNT</th>
                                        <th>MODE</th>
                                        <th>RECEIVED DATE</th>
                                        <th>START DATE</th>
                                        <th>TRANSACTION ID/ REF. NO</th>
                                        <th>BORROWER BANK A/C</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactionDetails
                                        ?.filter(transaction => transaction.Mode_of_Payment.toLowerCase() !== "cash")
                                        .map((transaction, index) => (
                                            <tr key={index}>
                                                <td>{transaction.Amount}/-</td>
                                                <td>{transaction.Mode_of_Payment}</td>
                                                <td>{formatDate(transaction.Received_Date)}</td>
                                                <td>{formatDate(transaction.Start_Date)}</td>
                                                <td>{transaction.Transaction_ID}</td>
                                                <td>{transaction.Bank_Name}</td>
                                            </tr>
                                        ))}
                                    {/* Total Loan Amount Row */}
                                    <tr>
                                        <td colSpan="6" className="text-center">
                                            <strong>
                                                TOTAL LOAN AMOUNT: RS. {
                                                    transactionDetails
                                                        ?.filter(transaction => transaction.Mode_of_Payment.toLowerCase() !== "cash")
                                                        .reduce((total, transaction) => total + (Number(transaction.Amount) || 0), 0)
                                                }
                                            </strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                        </div>
                    </>
                )}
                {customer?.cashCount > 0 && (
                    <>
                <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}><strong>Cash Amount Received Details as follows :-</strong></p>
                <div style={{ padding: "7px" }}>
                    <table border="1" cellPadding="2" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>LOAN AMOUNT</th>
                                <th>MODE</th>
                                <th>RECEIVED DATE</th>
                                <th>START DATE</th>
                                <th>CASH RECEIPT NO</th>
                                <th>CASH RECEIVED BY</th>
                                <th>CASH HANDOVERED TO</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactionDetails
                                ?.filter(transaction => transaction.Mode_of_Payment.toLowerCase() === "cash")
                                .map((transaction, index) => (
                                    <tr key={index}>
                                        <td>{transaction.Amount}/-</td>
                                        <td>{transaction.Mode_of_Payment}</td>
                                        <td>{formatDate(transaction.Received_Date)}</td>
                                        <td>{formatDate(transaction.Start_Date)}</td>
                                        <td>{transaction.Transaction_ID}</td>
                                        <td>{transaction.Cash_Received_By}</td>
                                        <td>{transaction.Cash_Handovered_To}</td>
                                    </tr>
                                ))}
                            {/* Total Loan Amount Row */}
                            <tr>
                                <td colSpan="7" className="text-center">
                                    <strong>
                                        TOTAL LOAN AMOUNT: RS. {
                                            transactionDetails
                                                ?.filter(transaction => transaction.Mode_of_Payment.toLowerCase() === "cash")
                                                .reduce((total, transaction) => total + (Number(transaction.Amount) || 0), 0)
                                        }
                                    </strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>


                </div>
                </>
            )}
                <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>Some conditions which are agreed by both parties :</strong>
                </p>


                <div style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>3. Lock-in-Period:</strong>
                    <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        A) Lender need to keep loan amount with the borrower minimum for {customer?.LockIn_Tenure} from the start date of this Loan Agreement which is {formatDate(customer?.LockIn_Period_From_Date)}.
                    </p>
                    <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        B) Withdrawing an amount before {customer?.LockIn_Tenure} is not allowed in any circumstances.
                    </p>
                    <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        C) If the Lender withdraws forcefully loan amount Part or full Amount
                        before {customer?.LockIn_Tenure} lock-in-period end which is {formatDate(customer?.LockIn_Period_To_Date)} then a small
                        part of loss adjustment will be applicable i.e {customer?.Penalty_Percent}% on the entire Capital
                        which is Rs. {customer?.Penalty_Amount}/-.
                    </p>
                    <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        <strong>D) This lock-in-period condition is applicable only for the lender, not to
                            the borrower.</strong>
                    </p>
                </div>

                <div style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>4. Withdrawal:</strong>
                    <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        A) This clause specifies that the loan is to be paid by the borrower to the
                        lender at the end of this agreement period, the withdrawal can be a lump
                        sum or part pay, as per the lender needs.
                    </p>
                    <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        B) Lender need to give proper notice and notice period to the borrower
                        as per the slab-wise withdrawal Notice Period Schedule for any kind of
                        withdrawals whether it is part withdrawal or full withdrawal.
                    </p>

                    <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        C) Whenever a lender requests withdrawal from the borrower, the lender
                        needs to send a written Email from his/her registered Email ID {customer?.Lender_Email_ID} to Borrower E-mail ID accounts@etiadmin.com.
                    </p>
                    <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0, marginTop: "10px" }}>
                        D) Withdrawal shall be honored and paid in Part or Lump Sum by the
                        Borrower as per Slab-wise withdrawal Payment Schedule from the date of
                        withdrawal/ repayment request by lender.
                    </p>
                    <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        <strong>E) Very Important :</strong> During this Slab-wise withdrawal Payment Schedule
                        period, it is agreed by the lender that there will be no any type
                        Interest/Cost/Charges shall be paid on the lender's amount by Borrower
                        for the period shown in the below table.
                    </p>
                </div>

                <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}><strong>Slab-wise Withdrawal Payment Schedule:</strong></p>
                <div style={{ padding: "7px" }}>
                    <table style={{ width: "100%", borderCollapse: "collapse" }} border="1" cellPadding="2" cellSpacing="0">
                        <thead>
                            <tr>
                                <th style={{ textAlign: "center" }}>RANGE AMOUNT ( IN RUPEES )</th>
                                <th style={{ textAlign: "center" }}>NOTICE PERIOD</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: "center" }}>1 LAKH TO 3 LAKH</td>
                                <td style={{ textAlign: "center" }}>15 DAYS</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "center" }}>4 LAKH TO 7 LAKH</td>
                                <td style={{ textAlign: "center" }}>25 DAYS</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "center" }}>8 LAKH TO 10 LAKH</td>
                                <td style={{ textAlign: "center" }}>35 DAYS</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "center" }}>11 LAKH TO 15 LAKH</td>
                                <td style={{ textAlign: "center" }}>45 DAYS</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "center" }}>16 LAKH TO 25 LAKH</td>
                                <td style={{ textAlign: "center" }}>60 DAYS</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "center" }}>ABOVE 26 LAKH</td>
                                <td style={{ textAlign: "center" }}>72 DAYS</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            {/* <div style={{ pageBreakBefore: 'always' }}></div> */}
            <div id="page3">
                <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    F) Lender will get Part Withdrawal or full withdrawal only after submitting
                    a Declaration Part 1 & Declaration Part 2 including sign of witness &
                    Nominee before 10 days of last date of Slab-wise withdrawal Payment
                    Schedule.
                </p>
                <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    G) Lender will get Part Withdrawal or full withdrawal only after
                    submitting Current & Previous Original Agreements before 10 days of last
                    date of Slab-wise withdrawal Payment Schedule.
                </p>
                <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    H) Borrower don’t do a withdrawal process between 1st of every month
                    to 12th of every month, so whatever request Borrower gets from 1st to
                    12th of month borrower process after 15th of following month.
                </p>
                <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    I) If the lender is withdrawing the whole amount or part amount after 1.5
                    years, considering the previous one or more agreements then if Borrower
                    require time to investigate older transaction’s records to tally and check of
                    loan amount history and to find out repaid amount history, for this
                    investigation whatever days Borrower require additionally to re-check, in
                    this case borrower is not liable to pay any interest or charges to the
                    lender for those additional re-checking period.
                </p>
                <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>J) Emergency Withdrawal means Sudden Withdrawal is not allowed in
                        any circumstances, but in case of high level of medical emergency then
                        the lender should bear a small part of the loss adjustment, which can be {customer?.Penalty_Percent}%
                        or more on the entire Capital, depending upon that time borrowers
                        financial condition.</strong>
                </p>
                <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>K) Very Important: If the funds are not available with the borrower at
                        the time of the lender's medical emergency withdrawal request then
                        sudden withdrawal payment will not be possible for the borrower to
                        give and the lender will not force the borrower to give sudden
                        withdrawal payment at that time.</strong>
                </p>
                <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>L) Therefore the lender should not give that amount which is kept for
                        emergencies and the lender should take care of the emergency fund
                        from other sources.</strong>
                </p>
                <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    M) Withdrawal or Part Refund of loan will be sent only in that bank
                    account from which lender given loan amount to the Borrower.
                </p>
                <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    N) During Part Refund of Loan Amount or Part or Full Withdrawal, if any
                    transaction happened in cash mode then, counting of currency Notes and
                    checking of Currency Notes and checking their quality and detection of
                    fake notes is the responsibility of the lender only.
                </p>
                <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    O) Once Lender receives Cash Loan Amount and Leaving borrower’s
                    premises then there will be no any complaint will be considered or no
                    replacement of Currency notes. So Lender needs to check everything
                    about the cash before leaving the borrower's Premises.
                </p>
                <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>5. Loan Security: </strong>This loan is accepted by the borrower without giving any
                    collateral or asset like house or vehicle or anything else to the lender,
                    nothing is pledged as collateral against this loan amount mentioned in
                    this agreement and the lender is aware about this as well as agrees to
                    give the loan without any collateral or mortgage.
                </p>
                <div style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>6. Prepayment Clause:</strong><br />
                    <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        A) Prepayment means early payment of loan, if the borrower wants to do
                        prepayment before this agreement end period, then borrower can do
                        part prepayment or entire payment of loan, at any point of time before
                        the end of {formatDate(customer?.Loan_End_Date)}.
                    </p>
                    <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        B) Pre- payment of loan can be on immediate effect/ on the spot payment
                        or with 15/30 days notice period as per borrower need.
                    </p>
                    <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        C)<strong> In case of early payment of a loan, Lender cannot force the borrower
                            to continue this agreement if the borrower is not willing to continue
                            this agreement.</strong>
                    </p>
                    <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        D)<strong> Borrower no need to give notice, intimation, call or message or e-mail
                            to the lender for immediate prepayment of loan before agreement
                            end period and between lock-in-period of agreement.</strong>
                    </p>
                    <div style={{ padding: "7px", marginBottom: 0 }}>
                        <h6><strong>7. KYC Details:</strong></h6>
                        <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                            A) Lender agrees to give KYC Documents like 1) ID Proof, 2) Address Proof,
                            3) Photos, and other all needful documents to make this Loan
                            agreement.
                        </p>
                        <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                            B) As per lender given information, on Date: {formatDate(customer?.KYC_Date)} Time: {formatTime(customer?.KYC_Time)} on
                            Our Mobile No. {customer?.Borrower_Mobile} from lender’s Registered
                            mobile no. {customer?.Lender_Registered_Mobile_No}, lenders Email ID and Mobile no. are as
                            follows:
                        </p>

                        <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                            <strong>Email ID : </strong>{customer?.Lender_Email_ID}
                        </p>

                        <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                            <strong>Mobile No : </strong>{customer?.Lender_Mobile_No}
                        </p>
                        <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                            C) In future Whatever communication with the lender will be through the
                            above given email ID & Mobile Number only which lender has given.

                        </p>
                        <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                            D) In future if Borrower need any kind of KYC Documents related to this
                            Loan Agreement or Banking records related to the loan Amount
                            mention in this Loan agreement as well as any clarification related to
                            transactions then lender should support and co-operate to the
                            borrower without delay, otherwise it will consider as lender is no more
                            interested to continue this Loan Agreement and borrower will
                            discontinue this Loan Agreement before end period by giving loan
                            amount back to the lender with or without notice.
                        </p>
                    </div>

                    <div style={{ padding: "7px", marginBottom: 0 }}>
                        <h6><strong>8. Agreement Charges & Other Charges:</strong></h6>
                        <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                            A) This Agreement’s total cost Rs. 450/- (Four Hundred Fifty Rupees Only) will be
                            borne by Lender only.
                        </p>
                        <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                            B) In future if any other Expenses incurred like courier, post or any
                            stationary charges, it will be borne by Lender only.
                        </p>
                    </div>
                </div>
            </div>
            {/* <div style={{ pageBreakBefore: 'always' }}></div> */}
            <div id="page4" style={{ marginTop: "10px" }}>
                <div style={{ padding: "7px", marginBottom: 0 }}>
                    <h6><strong>9. Nominee Clause:</strong></h6>
                    <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        Lender {customer?.Lender_PreName} {customer?.Lender_Name} Here by Appointing {customer?.Nominee1_PreName} {customer?.Nominee1_Name} As a Nominee In {customer?.Nomination_Gender} Loan Amount is Rs. {customer?.Nominee_Loan_Amount}/- on today {formatDate(customer?.Nomination_Date)}.
                    </p>

                    <div style={{ padding: "7px" }}>
                        <table style={{ width: "100%", borderCollapse: "collapse" }} border="1" cellPadding="2" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: "left" }} >NOMINEE NAME</th>
                                    <th style={{ textAlign: "left" }} >NOMINEE DOB</th>
                                    <th style={{ textAlign: "left" }} >RELATION WITH LENDER</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: "left" }}>{customer?.Nominee1_PreName} {customer?.Nominee1_Name}</td>
                                    <td style={{ textAlign: "left" }}>{formatDate(customer?.Nominee_DOB)}</td>
                                    <td style={{ textAlign: "left" }}>{customer?.Nominee_Relation}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        <strong>A) </strong>After the lender's Death nominee needs to claim this loan amount, this
                        is the nominee's responsibility to claim.
                    </p>
                    <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        <strong>B) </strong>Due to any reason if the claim gets delayed then no any interest will be
                        applicable on loan amount as well as no interest will be paid on pending
                        interest amount.
                    </p>
                    <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        <strong>C) </strong>Due to any reason, if the nominee claims the lender’s loan amount to
                        the borrower after so many days or months or years then it's not the
                        borrower's fault, so the borrower is not liable to pay any interest on that
                        period
                    </p>
                    <div className="row">
                        <div className="col-md-6">
                            <p  className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                                <strong>Nominee’s sign &<br /> Thumb Impression</strong>
                            </p>
                            <p  className="agreementContent" style={{ padding: "7px", marginTop: '88px', fontWeight: 'bold' }}>
                                {customer?.Nominee1_PreName} {customer?.Nominee1_Name}<br />
                                (Date: &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
                            </p>
                        </div>
                        <div className="col-md-6 text-md-end">
                            <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                                <strong>Lender’s sign &<br /> Thumb Impression</strong>
                            </p>
                            <p className="agreementContent" style={{ padding: "7px", marginTop: '88px', fontWeight: 'bold' }}>
                                {customer?.Lender_PreName} {customer?.Lender_Name}<br />
                                (Date: &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
                            </p>
                        </div>


                    </div>
                </div>
                {(customer?.bankCount > 0 || customer?.onlineCount > 0) && (
                    <>
                        <div style={{ padding: "7px", marginBottom: 0, marginTop: "88px" }}>
                            <h6><strong>10 . Lender’s Bank Account Details :</strong></h6>
                            <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                                <strong>A) </strong>Due to any reason, lender Bank Account which was given to the
                                borrower at the time of agreement, if this lender's bank account got
                                closed or changed or blocked or anything wrong happen in lender's bank
                                account then lender should inform to the borrower in written, if lender is
                                not informing to the borrower in written in e-mail on
                                accounts@etiadmin.com e-mail Id before 25th of particular month then
                                Borrower is not responsible If part or full loan payment done from
                                borrower side to the lender's registered bank account which was
                                submitted by the lender at the time of agreement and if these amount is
                                not received to the lender then borrower is not liable for any kind of loss,
                                this is full responsibility of lender.
                            </p>
                            <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                                <strong>B) </strong>In any type of payment dispute of bank or payment gateway platforms
                                like google pay or phone pay, lender need to coordinate with their bank or
                                payment gateway service providers, the borrower will not coordinate with
                                the lender's bank or payment gateway service provider.
                            </p>
                        </div>
                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}><strong>Lender Bank Account Details For Online Pay-in : </strong></p>
                        <div style={{ padding: "7px" }}>
                            {PayInBank && PayInBank.map((payIn, index) => (
                                <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "10px" }} border="1" cellPadding="2" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "left" }} colSpan={2}>Bank Account Details {index + 1} :</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: "left", width: "188px", fontWeight: "bold" }}>Bank A/C Holder Name:</td>
                                            <td style={{ textAlign: "left" }}>{payIn?.PayIn_Bank_Account_Holder_PreName} {payIn?.PayIn_Bank_Account_Holder_Name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left", width: "188px", fontWeight: "bold" }}>Bank Name:</td>
                                            <td style={{ textAlign: "left" }}>{payIn?.PayIn_Bank_Name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left", width: "188px", fontWeight: "bold" }}>Account No.:</td>
                                            <td style={{ textAlign: "left" }}>{payIn?.PayIn_Account_Number}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left", width: "188px", fontWeight: "bold" }}>IFSC Code:</td>
                                            <td style={{ textAlign: "left" }}>{payIn?.PayIn_IFSC_Code}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left", width: "188px", fontWeight: "bold" }}>Branch:</td>
                                            <td style={{ textAlign: "left" }}>{payIn?.PayIn_Branch}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left", width: "188px", fontWeight: "bold" }}>Bank A/C Holder Type:</td>
                                            <td style={{ textAlign: "left" }}>{payIn?.PayIn_Account_Holder_Type}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left", width: "188px", fontWeight: "bold" }}>Bank A/C Type:</td>
                                            <td style={{ textAlign: "left" }}>{payIn?.PayIn_Account_Type}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            ))}
                        </div>

                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}><strong>Lender Bank Account Details For Online Pay-out : </strong></p>
                        <div style={{ padding: "7px" }}>
                            {PayOutBank && PayOutBank.map((payOut, index) => (
                                <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "10px" }} border="1" cellPadding="2" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "left" }} colSpan={2}>Bank Account Details {index + 1} :</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: "left", width: "188px", fontWeight: "bold" }}>Bank A/C Holder Name:</td>
                                            <td style={{ textAlign: "left" }}>{payOut?.PayOut_Bank_Account_Holder_PreName} {payOut?.PayOut_Bank_Account_Holder_Name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left", width: "188px", fontWeight: "bold" }}>Bank Name:</td>
                                            <td style={{ textAlign: "left" }}>{payOut?.PayOut_Bank_Name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left", width: "188px", fontWeight: "bold" }}>Account No.:</td>
                                            <td style={{ textAlign: "left" }}>{payOut?.PayOut_Account_Number}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left", width: "188px", fontWeight: "bold" }}>IFSC Code:</td>
                                            <td style={{ textAlign: "left" }}>{payOut?.PayOut_IFSC_Code}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left", width: "188px", fontWeight: "bold" }}>Branch:</td>
                                            <td style={{ textAlign: "left" }}>{payOut?.PayOut_Branch}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left", width: "188px", fontWeight: "bold" }}>Bank A/C Holder Type:</td>
                                            <td style={{ textAlign: "left" }}>{payOut?.PayOut_Account_Holder_Type}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "left", width: "188px", fontWeight: "bold" }}>Bank A/C Type:</td>
                                            <td style={{ textAlign: "left" }}>{payOut?.PayOut_Account_Type}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            ))}
                        </div>
                    </>
                )}
                {customer?.cashCount > 0 && (
                    <>
                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}><strong>Lender Details For Cash Pay-in And Pay-out : </strong></p>
                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                            <strong>A) </strong>Monthly Loan Refund amount and withdrawal amount will be get in cash mode only.
                        </p>
                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                            <strong>B) </strong>Lender will come to borrower place to receive loan refund amount as per pre-decided date.
                        </p>
                    </>)}
                {/* <p style={{ padding: "7px", marginBottom: 0 }}><strong>B) Lenders Bank Details For Payout:</strong></p>
                                    <div style={{ padding: "7px" }}>
                                        <table style={{ width: "100%", borderCollapse: "collapse" }} border="1" cellPadding="2" cellSpacing="0">
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "left" }} colSpan={2}>Bank Account Details :</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "left", width: "177px" }}>Bank A/C Holder Name:</td>
                                                    <td style={{ textAlign: "left" }}>{customer?.Payout_Bank_Account_Holder_PreName} {customer?.Payout_Bank_Account_Holder_Name}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left", width: "177px" }}>Bank Name:</td>
                                                <td style={{ textAlign: "left" }}>{customer?.Payout_Bank_Name}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left", width: "177px" }}>Account No.:</td>
                                                    <td style={{ textAlign: "left" }}>{customer?.Payout_Account_Number}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left", width: "177px" }}>IFSC Code:</td>
                                                    <td style={{ textAlign: "left" }}>{customer?.Payout_IFSC_Code}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left", width: "177px" }}>Branch:</td>
                                                    <td style={{ textAlign: "left" }}>{customer?.Payout_Branch}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left", width: "177px" }}>Bank A/C Holder Type:</td>
                                                    <td style={{ textAlign: "left" }}>{customer?.Payout_Account_Holder_Type}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left", width: "177px" }}>Bank A/C Type:</td>
                                                    <td style={{ textAlign: "left" }}>{customer?.Payout_Account_Type}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> */}
                <div style={{ padding: "7px", marginBottom: 0 }}>
                    <h6><strong>11. Taxation:</strong></h6>
                    <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        A) This Agreement is strictly not used for submitting ITR.
                    </p>
                    <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        B) This Agreement is not to show to the Chartered Accountant.
                    </p>
                    <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        C) This Agreement is not to use as an Income proof for any purpose.
                    </p>

                </div>

            </div>
            {/* <div style={{ pageBreakBefore: 'always' }}></div> */}
            <div id="page5">
                <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>D) Very Important: </strong>This Agreement is only an Agreement which is made
                    for two parties Lender & Borrower to obey all the terms and conditions
                    and it should be kept separate from any other use.
                </p>
                <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    E) All Tax or its related expenses will be borne by Lender only.
                </p>
                <div style={{ padding: "7px", marginBottom: 0, marginTop: "99px" }}>
                    <h6><strong>12. Others:</strong></h6>
                    <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        A) All Previous Agreement issued on the same fund’s Transaction IDs will
                        be considered as Laps/Barred/ cancelled and only this agreement will
                        be valid for all mentioned transaction IDs, (Excluding repeated
                        Transaction IDs Amount) Borrower is liable to refund only this amount
                        which is mentioned in this agreement. (Excluding repeated Transaction
                        IDs Amount)
                    </p>
                    <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        B) This is very clear that any communication regarding this loan
                        agreement will be in between lender & borrower only.
                    </p>
                    <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        C) <strong>Very Important: </strong>Related to this loan agreement, Borrower will not
                        meet or talk with the lender's nominee, witness, spouse, relatives,
                        friends or any other person. If any dispute Occurred then Borrower is
                        Answerable to  <strong>{customer?.Lender_PreName} {customer?.Lender_Name}</strong> Only.
                    </p>

                </div>
                <div style={{ padding: "7px", marginBottom: 0 }}>
                    <h6><strong>13. Meeting with Borrower:-</strong></h6>
                    <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        A) Lender should contact first to Borrower Primary Contact Number. If
                        there is no response in 3 days then, the lender can contact the
                        secondary contact number, if the lender is not getting a response from
                        the secondary contact Number then only the lender can contact the
                        borrower direct contact no.
                    </p>
                    <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        <h6><strong>B) Contact Details of Borrower as follows:</strong></h6>
                        <table style={{ width: "100%", borderCollapse: "collapse" }} border="1" cellPadding="2" cellSpacing="0">

                            <tbody>
                                <tr>
                                    <td style={{ textAlign: "left" }}><strong>Primary Contact No.</strong></td>

                                    <td style={{ textAlign: "left" }}>7304223366</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "left" }}><strong>Primary Email ID</strong></td>

                                    <td style={{ textAlign: "left" }}>accounts@etiadmin.com</td>
                                </tr>   <tr>
                                    <td style={{ textAlign: "left" }}><strong>Authorised Person on Behalf of
                                        Pravin Dhuri</strong></td>

                                    <td style={{ textAlign: "left" }}>Miss. Laxmi Avanuri</td>
                                </tr>   <tr>
                                    <td style={{ textAlign: "left" }}><strong>Secondary Contact No.</strong></td>

                                    <td style={{ textAlign: "left" }}>7715994415</td>
                                </tr>   <tr>
                                    <td style={{ textAlign: "left" }}><strong>Pravin Dhuri Direct Contact No.</strong></td>

                                    <td style={{ textAlign: "left" }}>9833227272</td>
                                </tr>

                            </tbody>
                        </table>
                    </p>
                    <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        <strong>C) Very Important: </strong>The above mentioned contact no can be changed in
                        future. If the contact no is changed in that case the borrower will update
                        the lender the new contact no.
                    </p>
                    <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        D) If Lender want to meet with the borrower for any kind of Meeting,
                        then lender must inform prior 3 days to the borrower
                    </p>
                    <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        E) Borrower will be available on Saturday for a meeting with a prior
                        schedule.
                    </p>
                    <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                        F) There will be no availability of borrower’s on Friday so avoid coming &
                        meeting on Friday.
                    </p>

                    <p className="agreementContent" style={{ padding: "7px", marginBottom: 0, marginTop: "222px" }}>
                        <strong>This loan agreement is signed by both the parties in order to avoid any
                            dispute that may occur later.</strong>
                    </p>
                </div>

                <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>Myself {customer?.Lender_PreName} {customer?.Lender_Name}, declaring that all above said terms
                        & conditions are totally agreed by me and giving this statement without
                        any pressure or under no intoxication.</strong>
                </p>
                <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>Date: </strong>__________________
                </p>
                <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>Place: </strong>__________________
                </p>
                <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>Lender Name: {customer?.Lender_PreName} {customer?.Lender_Name}</strong>
                </p>
                <div className="row" style={{ marginTop: '88px', fontWeight: 'bold' }}>
                    <div className="col-md-12">
                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                            Lender Sign & Thumb Impression
                        </p>
                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0, marginTop: 0 }}>
                            (Date: &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
                        </p>
                    </div>



                </div>
                <p className="agreementContent" style={{ padding: "7px", marginBottom: 0, marginTop: '88px' }}>
                    <strong>Myself Mr. Pravin Mahadev Dhuri, declaring that all above said terms &
                        conditions are totally agreed by me and giving this statement without
                        any pressure or under no intoxication.</strong>
                </p>
                <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>Date: </strong>__________________
                </p>
                <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>Place: </strong>__________________
                </p>
                <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                    <strong>Borrower Name: Mr. Pravin Mahadev Dhuri</strong>
                </p>

                <div className="row" style={{ marginTop: '88px', fontWeight: 'bold' }}>

                    <div className="col-md-12">
                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                            Borrower Sign & Thumb Impression
                        </p>
                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0, marginTop: 0 }}>
                            (Date: &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)

                        </p>
                    </div>


                </div>

            </div>
            <div style={{ pageBreakBefore: 'always' }}></div>
            <div id="page6" style={{ marginTop: "10px" }}>

                <div className="row">
                    {/* Borrower Details */}
                    <div className="col-md-5">
                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}><strong>Borrower Name & Address:</strong><br />
                            <strong>Name: </strong>Mr. Pravin Mahadev Dhuri<br />

                            <strong>Add: </strong>Flat no. 503, C Wing, Sarvoday Pooja Chs,
                            5th Floor, Barave Road, Near Ayush Hospital,
                            Khadakpada, Kalyan (W) 421301
                        </p>

                    </div>
                    <div className="col-md-3">
                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                            <strong>Across Photo Sign<br />Thumb Impression</strong>
                        </p>

                    </div>

                    {/* Signature Section */}
                    <div className="col-md-4 d-flex flex-column align-items-center">
                        <div className="border" style={{ width: '100px', height: '120px' }}></div>
                        <p className="agreementContent mt-2 mb-4 fw-bold"></p>
                        <p className=" agreementContentmb-2">(Date: &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</p>
                    </div>
                </div>

                <div className="row">
                    {/* Borrower Details */}
                    <div className="col-md-5">
                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}><strong>Lender Name & Address:</strong><br />
                            <strong>Name: </strong>{customer?.Lender_PreName} {customer?.Lender_Name}<br />

                            <strong>Add: </strong>{customer?.Lender_Address}
                        </p>

                    </div>
                    <div className="col-md-3">
                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                            <strong>Across Photo Sign<br />Thumb Impression</strong>
                        </p>

                    </div>

                    {/* Signature Section */}
                    <div className="col-md-4 d-flex flex-column align-items-center">
                        <div className="border" style={{ width: '100px', height: '120px' }}></div>
                        <p className="agreementContent mt-2 mb-4 fw-bold"></p>
                        <p className="agreementContent mb-2">(Date: &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</p>
                    </div>
                </div>

                <div className="row">
                    {/* Borrower Details */}
                    <div className="col-md-5">
                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}><strong>Nominee Name & Address:</strong><br />
                            <strong>Name: </strong>{customer?.Nominee1_PreName} {customer?.Nominee1_Name}<br />

                            <strong>Add: </strong>{customer?.Nominee1_Address}
                        </p>

                    </div>
                    <div className="col-md-3">
                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                            <strong>Across Photo Sign<br />Thumb Impression</strong>
                        </p>

                    </div>

                    {/* Signature Section */}
                    <div className="col-md-4 d-flex flex-column align-items-center">
                        <div className="border" style={{ width: '100px', height: '120px' }}></div>
                        <p className="agreementContent mt-2 mb-4 fw-bold"></p>
                        <p className="agreementContent mb-2">(Date: &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</p>
                    </div>
                </div>

                <div className="row">
                    {/* Borrower Details */}
                    <div className="col-md-5">
                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}><strong>Witness Signature:</strong><br />
                            <strong>Witness of Execution of Agreement</strong><br />
                            <strong>Name: </strong>{customer?.Declaration_Witness_PreName} {customer?.Declaration_Witness_Name}<br />
                            <strong>Address: </strong>{customer?.Declaration_Witness_Address}
                        </p>

                    </div>
                    <div className="col-md-3">
                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                            <strong>Across Photo Sign<br />
                                {/* Thumb Impression */}
                            </strong>
                        </p>

                    </div>
                    {/* Signature Section */}
                    <div className="col-md-4 d-flex flex-column align-items-center">
                        <div className="border" style={{ width: '100px', height: '120px' }}></div>
                        <p className="agreementContent mt-2 mb-4 fw-bold"></p>
                        <p  className="agreementContent mb-2">(Date: &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</p>
                    </div>
                </div>

                <div className="row">
                    {/* Borrower Details */}
                    <div className="col-md-5">
                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}><strong>Witness of Execution of Agreement:</strong><br />
                            <strong>Name: </strong>Mrs. Ujwala Parshuram Thul<br />
                            <strong>Add: </strong>Flat no.506, C Wing,
                            Sarvoday Pooja Chs, Near Ayush Hospital,
                            Khadakpada, Kalyan (W) 421301
                        </p>

                    </div>
                    <div className="col-md-3">
                        <p className="agreementContent" style={{ padding: "7px", marginBottom: 0 }}>
                            <strong>Across Photo Sign<br />
                                {/* Thumb Impression */}
                            </strong>
                        </p>

                    </div>
                    {/* Signature Section */}
                    <div className="col-md-4 d-flex flex-column align-items-center">
                        <div className="border" style={{ width: '100px', height: '120px' }}></div>
                        <p className="mt-2 mb-4 fw-bold"></p>
                        <p className="mb-0">(Date: &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</p>
                    </div>
                </div>

            </div>
        </>
    );
}
export default AgreementContent;;